import {
  Box,
  Checkbox,
  Paper,
  TableContainer,
  styled,
  tableCellClasses,
  IconButton,
  Typography,
  Tooltip,
  TextField,
  Divider,
  FormLabel
} from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Editor from '@monaco-editor/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { StyledTableRow2 } from '../../../components/base/Table';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Flex from '../../../components/base/Flex';
import EventRecordSvg from '../../../components/svg/EventRecordSvg';
import ViewStepSvg from '../../../components/svg/ViewStepSvg';
import CustomModal from '../../../components/base/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import useAwaitModal from '../../../hooks/useAwaitModal';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import {
  AssertionInput,
  AssertionSubtypeDropdown,
  AssertionTypeDropdown,
  assertionTypes
} from './AssertionModal';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import { toast } from 'react-toastify';
import OutlinedButton from '../../../components/base/OutlinedButton';
import MobileFunctionVariables from './MobileFunctionVariables';
import { useTheme } from '@emotion/react';
import { ErrorBanner } from './ErrorBanner';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.black_white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    borderBottom: '2px solid #E3E3E3',
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    }
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: 'all-scroll',
    borderBottom: '1px solid #E3E3E3',
    padding: '8px 0px',
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    },
    '.cell-content-wrapper': {
      padding: '16px',
      backgroundColor: theme.palette.background.testCaseList,
      color: theme.palette.text.resultTableText,
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: '40px',
      display: 'flex',
      alignItems: 'center'
    },

    '&:first-child': {
      '.cell-content-wrapper': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }
    },

    '&:last-child': {
      '.cell-content-wrapper': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  }
}));

const ItemTypes = {
  ROW: 'row'
};

const Badge = ({ status }) => {
  const colors = {
    success: '#00CA08',
    error: '#EF1B1B',
    skipped: '#D4D2D2',
    ignored: '#474747'
  };

  const texts = {
    success: 'Passed',
    error: 'Failed',
    skipped: 'Skipped',
    ignored: 'Ignored'
  };

  return (
    <Box
      sx={{
        py: 0.2,
        width: { xs: '50px', sm: '64px' },
        backgroundColor: colors[status],
        color: '#fff',
        textTransform: 'capitalize',
        textAlign: 'center',
        fontSize: '0.7rem',
        borderTopLeftRadius: 5,
        zIndex: 1,
        borderBottomRightRadius: 5
      }}>
      {texts[status]}
    </Box>
  );
};

const StepDetailsModal = ({
  open,
  onClose,
  handleInputFieldUpdate,
  actions,
  setActions,
  setUnsaved,
  id,
  handleEditSelector
}) => {
  const { batchId } = useParams();
  const theme = useTheme();
  const [cursorIndex, setCursorIndex] = useState(null);
  const action = actions.find((a) => a.id === id);
  const [code, setCode] = useState(
    JSON.stringify(action?.element || action?.attributes, null, 2) || ''
  );
  const index = actions.findIndex((a) => a.id === id);

  const [
    requestSystemVariableCreation,
    {
      open: openSystemVariableCreation,
      onClose: onCloseSystemVariableCreation,
      onComplete: completeSystemVariableCreation
    }
  ] = useAwaitModal();

  const preConditionMode = action?.conditions?.type || 'always-run';

  useEffect(() => {
    setCode(JSON.stringify(action?.element || action?.attributes, null, 2) || '');
  }, [id]);

  const handleInputEditorChange = (value, property) => {
    setCode(value);
    const editedElement = JSON.parse(value);
    handleEditSelector(index, editedElement, property);
    setUnsaved(true);
  };

  const editorOptions = {
    readOnly: !!batchId,
    fontSize: 12,
    formatOnPaste: true,
    renderIndentGuides: true,
    formatOnType: true,
    inlineSuggest: true,
    autoClosingBrackets: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  const handlePreconditionUpdate = (preConditionMode) => {
    // if the action.type is "type", then we need to set the attribute property and value from the preceding action as that will contain the default attribute where the type action will take place

    const typeAction = actions[index - 1];
    const attributesOptions = Object.entries(
      (action.type === 'type' ? typeAction : action)?.element?.attributes ||
        action?.attributes ||
        {}
    );

    const [attributeProperty, attributeValue] =
      attributesOptions?.[2] || attributesOptions?.[1] || attributesOptions?.[0] || [];

    setActions((prevActions) =>
      prevActions.map((action) => {
        if (action.id === id) {
          const updatedConditions = {
            type: preConditionMode,
            attribute_property:
              preConditionMode !== 'always-run' && preConditionMode !== 'skip'
                ? action?.conditions?.attribute_property || attributeProperty
                : undefined,
            attribute_value:
              preConditionMode !== 'always-run' && preConditionMode !== 'skip'
                ? action?.conditions?.attribute_value || attributeValue
                : undefined
          };

          return {
            ...action,
            conditions: updatedConditions
          };
        } else {
          return action;
        }
      })
    );
    setUnsaved(true);
  };

  const handlePreconditionAttributeOrValue = (key, value) => {
    setActions((prevActions) =>
      prevActions.map((action) => {
        if (action.id === id) {
          return {
            ...action,
            conditions: {
              ...action.conditions,
              [key]: value
            }
          };
        } else {
          return action;
        }
      })
    );
    setUnsaved(true);
  };

  const handleFailureModeUpdate = (value) => {
    setActions((prevActions) =>
      prevActions.map((action) => {
        if (action.id === id) {
          return {
            ...action,
            onError: value
          };
        } else {
          return action;
        }
      })
    );
    setUnsaved(true);
  };

  const renderInputField = ['type'].includes(action?.type);
  const renderAssertionInputField = ['assert'].includes(action?.type);
  const renderRadioButton = ['biometry'].includes(action?.type);
  const renderCodeEditor = ['type', 'keypress', 'biometry', 'openUrl'].includes(action?.type);
  const renderTimeoutAndStepDelayFields = ['type', 'biometry', 'assert'].includes(action?.type);

  const componentPreconditionElements = () => (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <TextField
        size={'small'}
        label={'Attribute property'}
        fullWidth
        value={action?.conditions?.attribute_property || ''}
        disabled={!!batchId}
        onChange={(event) =>
          handlePreconditionAttributeOrValue('attribute_property', event.target.value)
        }
      />
      <TextField
        size={'small'}
        label={'Attribute value'}
        value={action?.conditions?.attribute_value || ''}
        fullWidth
        disabled={!!batchId}
        onChange={(event) =>
          handlePreconditionAttributeOrValue('attribute_value', event.target.value)
        }
      />
    </Box>
  );

  return (
    <CustomModal open={open}>
      <Box py={2} px={1} width={'80vw'} maxWidth={'500px'} component={'form'}>
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography
            as={'h4'}
            color={theme.palette.text.custom}
            sx={{
              fontSize: '1.4rem'
            }}>
            {index + 1}. Step Details
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: 25 }} />
          </IconButton>
        </Flex>

        <CustomScrollbar sx={{ overflowY: 'scroll', maxHeight: '70vh' }}>
          <Box py={2} pr={2}>
            {!renderTimeoutAndStepDelayFields && (
              <>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <TextField
                    size={'small'}
                    label={'Step Timeout'}
                    fullWidth
                    value={action?.timeout || ''}
                    disabled={!!batchId}
                    onChange={(event) =>
                      handleInputFieldUpdate(index, 'timeout', event.target.value)
                    }
                  />
                  <TextField
                    size={'small'}
                    label={'Step Delay'}
                    value={action?.step_delay || ''}
                    fullWidth
                    disabled={!!batchId}
                    onChange={(event) =>
                      handleInputFieldUpdate(index, 'step_delay', event.target.value)
                    }
                  />
                </Box>
                <TextField
                  size={'small'}
                  label={'Description'}
                  rows={2}
                  sx={{ my: 1 }}
                  fullWidth
                  value={action?.description || ''}
                  disabled={!!batchId}
                  onChange={(event) =>
                    handleInputFieldUpdate(index, 'description', event.target.value)
                  }
                />
              </>
            )}

            {renderInputField && (
              <Box>
                <TextField
                  size={'small'}
                  label={'Text to assign'}
                  disabled={!!batchId}
                  multiline
                  value={action?.value || ''}
                  fullWidth
                  onSelect={(e) => setCursorIndex(e.target.selectionStart)}
                  sx={{ mb: 1 }}
                  onChange={(e) => handleInputFieldUpdate(index, 'value', e.target.value)}
                />

                {action.variableValue && (
                  <Typography
                    variant={'body2'}
                    sx={{ py: 1, px: 2, background: theme.palette.background.lowerToolBar, mb: 1 }}>
                    {action.variableValue}
                  </Typography>
                )}

                <OutlinedButton
                  disabled={!!batchId}
                  fullWidth
                  onClick={() => requestSystemVariableCreation()}>
                  Insert Function
                </OutlinedButton>
              </Box>
            )}

            {renderAssertionInputField && (
              <>
                <AssertionTypeDropdown
                  types={assertionTypes}
                  selectedType={action?.assertType || ''}
                  onTypeChange={(e) => handleInputFieldUpdate(index, 'assertType', e.target.value)}
                />
                <Box>
                  <AssertionSubtypeDropdown
                    selectedType={action?.assertType || ''}
                    selectedSubtype={action?.subtype}
                    onSubtypeChange={(e) =>
                      handleInputFieldUpdate(index, 'subtype', e.target.value)
                    }
                  />
                </Box>
                {action?.assertType !== 'component' && (
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <AssertionInput
                      selectedSubtype={action?.subtype || ''}
                      inputValue={action?.expectedValue || ''}
                      selectedType={action?.assertType || ''}
                      onInputChange={(e) =>
                        handleInputFieldUpdate(index, 'expectedValue', e.target.value)
                      }
                      rangeStart={action?.rangeStart || ''}
                      rangeEnd={action?.rangeEnd || ''}
                      handleRangeStartChange={(e) =>
                        handleInputFieldUpdate(index, 'rangeStart', e.target.value)
                      }
                      handleRangeEndChange={(e) =>
                        handleInputFieldUpdate(index, 'rangeEnd', e.target.value)
                      }
                    />
                  </FormControl>
                )}
              </>
            )}

            <Divider sx={{ my: 1 }} />

            <Box mb={2}>
              <FormControl>
                <FormLabel
                  id="Preconditions-controlled-radio-buttons-group"
                  sx={{
                    fontWeight: 'bold',
                    color: theme.palette.text.custom,
                    mt: { xs: 2, sm: 2 }
                  }}>
                  Failure Mode
                </FormLabel>
                <Typography textAlign={'left'} sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  When this step fails
                </Typography>
                <RadioGroup
                  aria-labelledby="Preconditions-controlled-radio-buttons-group"
                  name="Preconditions-controlled-radio-buttons-group"
                  value={action?.onError || 'fail'}
                  onChange={(event) => handleFailureModeUpdate(event.target.value)}>
                  <FormControlLabel
                    value="fail"
                    disabled={!!batchId}
                    control={
                      <Radio
                        sx={{
                          py: 0.5,
                          '&.Mui-checked': {
                            color: theme.palette.radio.main
                          }
                        }}
                        size="small"
                      />
                    }
                    label={
                      <Typography
                        fontSize={13}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          color: theme.palette.text.black_grey
                        }}>
                        Fail test immediately
                        <Tooltip title="When this step fails, the test is marked as failed, and subsequent steps are not executed">
                          <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                        </Tooltip>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="continue"
                    disabled={!!batchId}
                    control={
                      <Radio
                        sx={{
                          py: 0.5,
                          '&.Mui-checked': {
                            color: theme.palette.radio.main
                          }
                        }}
                        size="small"
                      />
                    }
                    label={
                      <Typography
                        fontSize={13}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          color: theme.palette.text.black_grey
                        }}>
                        Fail and continue
                        <Tooltip title="When this step fails, the test is marked as failed, but subsequent steps are executed.">
                          <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                        </Tooltip>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="ignore"
                    disabled={!!batchId}
                    control={
                      <Radio
                        sx={{
                          py: 0.5,
                          '&.Mui-checked': {
                            color: theme.palette.radio.main
                          }
                        }}
                        size="small"
                      />
                    }
                    label={
                      <Typography
                        fontSize={13}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          color: theme.palette.text.black_grey
                        }}>
                        Ignore failure
                        <Tooltip title="The 'Ignore failure' mode will execute all test cases even when the extension report a failure">
                          <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                        </Tooltip>
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box mb={2}>
              <FormLabel
                id="Preconditions-controlled-radio-buttons-group"
                sx={{
                  fontWeight: 'bold',
                  color: theme.palette.text.custom,
                  mt: { xs: 2, sm: 2 }
                }}>
                Pre-Condition Mode
              </FormLabel>
              <Typography textAlign={'left'} sx={{ fontSize: 13, opacity: 0.8 }}>
                When to run this step
              </Typography>
              <RadioGroup
                aria-labelledby="Preconditions-controlled-radio-buttons-group"
                name="Preconditions-controlled-radio-buttons-group"
                value={preConditionMode}
                onChange={(event) => handlePreconditionUpdate(event.target.value)}>
                <FormControlLabel
                  value="always-run"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                    />
                  }
                  disabled={!!batchId}
                  label={
                    <Typography
                      fontSize={13}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: theme.palette.text.black_grey
                      }}>
                      Always run
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="skip"
                  disabled={!!batchId}
                  control={
                    <Radio
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      size="small"
                    />
                  }
                  label={
                    <Typography
                      fontSize={13}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: theme.palette.text.black_grey
                      }}>
                      Never run (Skip)
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="component-visible"
                  disabled={!!batchId}
                  control={
                    <Radio
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      size="small"
                    />
                  }
                  label={
                    <Typography
                      fontSize={13}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: theme.palette.text.black_grey
                      }}>
                      Component visible
                    </Typography>
                  }
                />
                {preConditionMode === 'component-visible' && componentPreconditionElements()}

                <FormControlLabel
                  value="component-not-visible"
                  control={
                    <Radio
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      size="small"
                    />
                  }
                  disabled={!!batchId}
                  label={
                    <Typography
                      fontSize={13}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: theme.palette.text.black_grey
                      }}>
                      Component not visible
                    </Typography>
                  }
                />
                {preConditionMode === 'component-not-visible' && componentPreconditionElements()}

                <FormControlLabel
                  value="component-contains-text"
                  control={
                    <Radio
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      size="small"
                    />
                  }
                  disabled={!!batchId}
                  label={
                    <Typography
                      fontSize={13}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: theme.palette.text.black_grey
                      }}>
                      Component contains text
                    </Typography>
                  }
                />
                {preConditionMode === 'component-contains-text' && componentPreconditionElements()}

                <FormControlLabel
                  value="component-not-contains-text"
                  control={
                    <Radio
                      sx={{
                        py: 0.5,
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      size="small"
                    />
                  }
                  disabled={!!batchId}
                  label={
                    <Typography
                      fontSize={13}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: theme.palette.text.black_grey
                      }}>
                      Component not contains text
                    </Typography>
                  }
                />
                {preConditionMode === 'component-not-contains-text' &&
                  componentPreconditionElements()}
              </RadioGroup>
            </Box>

            <Divider sx={{ my: 2 }} />

            {!renderCodeEditor && (
              <Editor
                height={'150px'}
                theme={'vs-dark'}
                value={code || ''}
                onChange={(value, event) => {
                  const secondParameter = action?.type === 'assert' ? 'attributes' : 'element';
                  handleInputEditorChange(value, secondParameter);
                }}
                language={'json'}
                options={editorOptions}
              />
            )}

            {renderRadioButton && (
              <FormControl sx={{ px: 2, m: 2 }}>
                <RadioGroup
                  row
                  size={'small'}
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={action?.value || ''}
                  onChange={(e) => handleInputFieldUpdate(index, 'value', e.target.value)}
                  name="radio-buttons-group">
                  <FormControlLabel value={true} control={<Radio />} label="True" />
                  <FormControlLabel value={false} control={<Radio />} label="False" />
                </RadioGroup>
              </FormControl>
            )}

            {action?.status === 'error' && action?.error && (
              <Box
                sx={{
                  whiteSpace: 'pre-wrap',
                  fontSize: 12,
                  bgcolor: 'rgba(239, 27, 27, 0.2)',
                  py: 2
                }}
                component={'pre'}
                width={'inherit'}
                px={2}>
                {action.error}
              </Box>
            )}
          </Box>
        </CustomScrollbar>
      </Box>

      <MobileFunctionVariables
        open={openSystemVariableCreation}
        onClose={onCloseSystemVariableCreation}
        handleInputFieldUpdate={handleInputFieldUpdate}
        index={index}
        inputValue={action?.value || ''}
        cursorIndex={cursorIndex}
        onCompleteInsertion={completeSystemVariableCreation}
      />
    </CustomModal>
  );
};

const DraggableRow = React.memo(
  ({
    index,
    action,
    isOpen,
    onShowDetails,
    matchedStatus,
    handleEditSelector,
    setUnsaved,
    handleInputFieldUpdate,
    toggleStepsSelection,
    selectedStepIndexes,
    handleRecordingActionFromIndex,
    onDeleteStep,
    moveRow,
    findTestIndex,
    id,
    handleDragEnd,
    requestStepDetailsModal
  }) => {
    const { batchId } = useParams();
    const theme = useTheme();
    const [code, setCode] = useState(
      JSON.stringify(action?.element || action?.attributes, null, 2) || ''
    );
    const { requestConfirm, ConfirmationDialog } = useConfirmDialog();

    const handleDeleteStep = async () => {
      if (await requestConfirm()) return onDeleteStep(index);
    };

    const handleInputEditorChange = (value, property) => {
      setCode(value);
      const editedElement = JSON.parse(value);
      handleEditSelector(index, editedElement, property);
      setUnsaved(true);
    };

    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: ItemTypes.ROW,
        item: { id, index },
        end: (item, monitor) => {
          const didDrop = monitor.didDrop();
          if (didDrop) {
            // handleDragEnd();
          }
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        })
      }),
      [id, index]
    );

    const opacity = isDragging ? 0.7 : 1;

    const [, drop] = useDrop(
      () => ({
        accept: ItemTypes.ROW,
        hover(item, monitor) {
          const dragIndex = findTestIndex(item.id);
          const hoverIndex = findTestIndex(id);
          moveRow(dragIndex, hoverIndex);
        }
      }),
      [id, index, moveRow]
    );

    return (
      <StyledTableRow2
        ref={(node) => drag(drop(node))}
        sx={{ textDecoration: 'none', opacity: isDragging ? 0.5 : 1 }}>
        {!batchId && (
          <StyledTableCell2>
            <Box className={'cell-content-wrapper'}>
              <Box
                backgroundColor={theme.palette.background.lightGrey_dark}
                sx={{ borderBottomLeftRadius: '8px' }}>
                <Checkbox
                  size={'small'}
                  sx={{
                    '&.Mui-checked': {
                      color: theme.palette.radio.main
                    }
                  }}
                  onChange={() => toggleStepsSelection(action.id)}
                  checked={selectedStepIndexes.includes(action.id)}
                />
              </Box>
            </Box>
          </StyledTableCell2>
        )}
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>{index + 1}</Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'} sx={{ textTransform: 'capitalize' }}>
            {action?.type}
          </Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>{action?.element?.attributes?.class}</Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>
            {action?.element?.attributes['resource-id'] ||
              action?.attributes?.['resource-id'] ||
              ''}
          </Box>
        </StyledTableCell2>
        <StyledTableCell2>
          <Box className={'cell-content-wrapper'}>
            {action?.element?.attributes?.text ||
              action?.element?.accessibilityElements?.[0]?.accessibilityLabel ||
              action?.value ||
              action?.subtype ||
              action?.character ||
              action?.url ||
              ''}
          </Box>
        </StyledTableCell2>
        <StyledTableCell2 align="right" colSpan={4}>
          <Box className={'cell-content-wrapper'}></Box>
        </StyledTableCell2>
        <StyledTableCell2 align="right">
          <Box className={'cell-content-wrapper'}>
            <Flex sx={{ borderBottomLeftRadius: '8px' }} columnGap={1}>
              <Tooltip title={'View step details'}>
                <IconButton
                  size={'small'}
                  onClick={() => requestStepDetailsModal({ id: action.id })}>
                  <ViewStepSvg width={18} height={18} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Insert new steps after this step'}>
                <IconButton
                  size={'small'}
                  disabled={!!batchId}
                  onClick={() => handleRecordingActionFromIndex(index)}>
                  <EventRecordSvg width={18} height={18} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete step'}>
                <IconButton size={'small'} disabled={!!batchId} onClick={() => handleDeleteStep()}>
                  <DeleteSvg width={14} height={16} />
                </IconButton>
              </Tooltip>
              {action.status && <Badge status={action.status} />}
            </Flex>
          </Box>
        </StyledTableCell2>
        <ConfirmationDialog
          title={'Are you sure you want to delete this step?'}
          description={'This action is not reversible, you will need to record the step again.'}
          confirmLabel={'Delete'}
          confirmColor={'error'}
        />
      </StyledTableRow2>
    );
  }
);

const ListSteps = ({
  actions,
  matchedStatus,
  runResults,
  setActions,
  setUnsaved,
  toggleStepsSelection,
  selectedStepIndexes,
  selectAllSteps,
  handleInputFieldUpdate,
  handleRecordingActionFromIndex,
  reorderTestActions,
  findActionIndex,
  refetchPreviousRuns,
  failureTag,
  failedStepIndex,
  errorStep
}) => {
  const [showDetails, setShowDetails] = useState(null);
  const { batchId } = useParams();
  const theme = useTheme();

  const [, drop] = useDrop(() => ({ accept: ItemTypes.ROW }));

  const [
    requestStepDetailsModal,
    {
      open: openStepDetailsModal,
      onClose: onCloseStepDetailsModal,
      onComplete: completeStepDetailsModal,
      ...otherProps
    }
  ] = useAwaitModal();

  const allStepIds = actions.map((step) => step.id);
  const allSelected = allStepIds.every((id) => selectedStepIndexes.includes(id));

  const handleShowTestDetails = (index) => {
    setShowDetails((prevOpenRow) => (prevOpenRow === index ? null : index));
  };

  const handleEditSelector = (index, editedElement, property) => {
    setActions((prevActions) => {
      const newActions = [...prevActions];
      newActions[index] = {
        ...newActions[index],
        [property]: editedElement
      };
      return newActions;
    });
  };

  const deleteItemAndSetState = (index) => {
    if (index >= 0 && index < actions.length) {
      const newActions = [...actions];
      newActions.splice(index, 1);
      setActions(newActions);
      setUnsaved(true);
    } else {
      toast.error('Invalid index provided for deletion.');
    }
  };

  const runResultsById = (runResults || []).reduce(
    (acc, result) => ({ ...acc, [result.step_id || result.id]: result }),
    {}
  );

  const findTestIndex = useCallback(
    (id) => {
      return actions.findIndex((action) => action.id === id);
    },
    [actions]
  );

  const reorderTests = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex === dragIndex) return;

      const newActions = actions.map(({ preview, ...test }) => test);
      const draggedAction = newActions[dragIndex];

      newActions.splice(dragIndex, 1);
      newActions.splice(hoverIndex, 0, { ...draggedAction, preview: true });
      setActions(newActions);
      setUnsaved(true);
    },
    [actions, setActions, setUnsaved]
  );

  const handleDragEnd = useCallback(() => {
    const newActions = actions.map(({ preview, ...test }) => test);
    setActions(newActions);
  }, [actions, setActions]);

  return (
    <Box>
      {errorStep && (
        <ErrorBanner
          status={errorStep?.status}
          error={errorStep?.error}
          errorStep={errorStep}
          index={failedStepIndex}
          refetchPreviousRuns={refetchPreviousRuns}
          failureTag={failureTag}
          onViewDetails={() => requestStepDetailsModal({ id: errorStep?.id })}
        />
      )}

      <TableContainer
        component={Paper}
        sx={{
          mb: 2,
          boxShadow: 'none',
          position: 'relative',
          maxHeight: '45vh'
        }}>
        <Table stickyHeader aria-label={'sticky table'} sx={{ width: '100%', minWidth: 700 }}>
          <TableHead sx={{ zIndex: 5 }}>
            <TableRow>
              {!batchId && (
                <StyledTableCell>
                  <Tooltip title={'Select all'}>
                    <Checkbox
                      disabled={!actions?.length}
                      size={'small'}
                      sx={{
                        '&.Mui-checked': {
                          color: theme.palette.radio.main
                        }
                      }}
                      onChange={selectAllSteps}
                      checked={allSelected}
                    />
                  </Tooltip>
                </StyledTableCell>
              )}
              <StyledTableCell>S/N</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
              <StyledTableCell>Class</StyledTableCell>
              <StyledTableCell>Resource ID</StyledTableCell>
              <StyledTableCell>Action value</StyledTableCell>
              <StyledTableCell colSpan={4}></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={drop}>
            {actions?.map((action, i) => (
              <DraggableRow
                key={i}
                index={i}
                id={action.id}
                action={action}
                onShowDetails={handleShowTestDetails}
                isOpen={showDetails === i}
                matchedStatus={matchedStatus}
                handleEditSelector={handleEditSelector}
                handleInputFieldUpdate={handleInputFieldUpdate}
                setUnsaved={setUnsaved}
                selectedStepIndexes={selectedStepIndexes}
                toggleStepsSelection={toggleStepsSelection}
                handleRecordingActionFromIndex={handleRecordingActionFromIndex}
                onDeleteStep={deleteItemAndSetState}
                moveRow={reorderTests}
                findTestIndex={findTestIndex}
                handleDragEnd={handleDragEnd}
                requestStepDetailsModal={requestStepDetailsModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <StepDetailsModal
        open={openStepDetailsModal}
        onClose={onCloseStepDetailsModal}
        onComplete={completeStepDetailsModal}
        actions={actions}
        setActions={setActions}
        setUnsaved={setUnsaved}
        handleInputFieldUpdate={handleInputFieldUpdate}
        handleEditSelector={handleEditSelector}
        {...otherProps}
      />
    </Box>
  );
};

export default ListSteps;
