import { Box, Menu, MenuItem, Typography } from '@mui/material';
import Flex from '../../../components/base/Flex';
import { useMenu } from '../../../components/base/useMenu';
import CustomButton from '../../../components/customButton';
import { useTheme } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { ColorContext } from '../../../AppTheme';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useLocation, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { useCollaborators } from '../../../store/membersState';

const label = { inputProps: { 'aria-label': 'Tags checkbox' } };

const UsersFIlter = () => {
  const { triggerProps, menuProps } = useMenu();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const theme = useTheme();
  const { mode } = useContext(ColorContext);
  const { collaborators } = useCollaborators();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleTagsChange = (tag) => {
    let newSelectedUsers = [...selectedUsers];
    const existingIndex = newSelectedUsers.findIndex((existingTag) => existingTag.id === tag.id);

    if (existingIndex !== -1) {
      newSelectedUsers.splice(existingIndex, 1);
    } else {
      newSelectedUsers.push(tag);
    }
    setSelectedUsers(newSelectedUsers);

    const users_Ids = newSelectedUsers.map((user) => user.id);
    updateUrlQueryParams('users', users_Ids.join('-'));
  };

  const updateUrlQueryParams = (paramName, value) => {
    if (value) {
      queryParams.set(paramName, value);
    } else {
      queryParams.delete(paramName);
    }
    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    const filterByQueryParams = (paramKey, dataList, setData) => {
      const paramString = queryParams.get(paramKey);
      const paramIds = paramString?.split('-')?.map(Number);

      if (paramString && !!paramIds.length) {
        const filteredData = dataList?.filter((obj) => paramIds.includes(obj.id));
        setData(filteredData);
      }
    };

    filterByQueryParams('users', collaborators, setSelectedUsers);
  }, [collaborators]);

  const isCollaboratorSelected = (collaborator) => {
    return selectedUsers.some(
      (selectedCollaborator) => selectedCollaborator.id === collaborator.id
    );
  };

  return (
    <Box>
      <CustomButton
        disableRipple
        disableFocusRipple
        disableElevation
        {...triggerProps}
        fullWidth
        variant={'outlined'}
        label={
          <Flex>
            <FilterListIcon color={theme.palette.svg.primary} />{' '}
            <Typography variant={'body2'}>Filter by Users</Typography>
          </Flex>
        }
        sx={{
          textAlign: 'center',
          textTransform: 'capitalize',
          borderRadius: '0.4rem',
          color: theme.palette.button.textOutlined,
          border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
          py: { xs: '0.2rem', sm: '0.3rem' },
          px: 2,
          mr: 1,
          width: 'max-content',
          '&: hover': {
            background: 'transparent'
          }
        }}
      />
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '250px' }}>
          {collaborators?.map((collaborators) => (
            <MenuItem
              key={collaborators.id}
              onClick={() => handleTagsChange(collaborators)}
              sx={{ py: 0 }}>
              <Flex>
                <Checkbox
                  size={'small'}
                  {...label}
                  checked={!!isCollaboratorSelected(collaborators)}
                  sx={{
                    '&.Mui-checked': {
                      color: theme.palette.radio.main
                    }
                  }}
                />
                <Typography variant={'body2'}>{collaborators.name}</Typography>
              </Flex>
            </MenuItem>
          ))}
        </CustomScrollbar>
      </Menu>
    </Box>
  );
};

export default UsersFIlter;
