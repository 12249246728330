import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import React, { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { TestCaseContext } from '../../../store/testState';

const RenameModal = ({ open, onClose, onComplete, folder, test, refetch, _folderId, useCase }) => {
  const { clickedFolder, clickedTest } = useContext(TestCaseContext);
  const queryClient = useQueryClient();
  const { folderId } = useParams();
  const { search } = useLocation();
  const suiteID = new URLSearchParams(search).get('suite_id') || '';
  const [name, setName] = React.useState(suiteID ? '' : clickedTest?.name || clickedFolder.name);
  const activeProject = useActiveProject();

  const { mutateAsync: createTestSuite, isLoading: isSavingSuite } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-suites`,
    {
      // enabled: !!activeProject?.id,
      method: 'POST',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Test Suite created successfully');
        onComplete();
        queryClient.invalidateQueries({ queryKey: ['api-suites'] });
        //invalidate the fetch suite query
      }
    }
  );

  const { mutateAsync: updateTestName, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/api-tests/${clickedTest?.id}`,
    {
      enabled: !!activeProject?.id,
      method: 'PUT',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Test name updated successfully');
        refetch();
        onComplete();
      }
    }
  );

  const { mutateAsync: updateFolderName, isLoading: isUpdatingFolder } = useScandiumMutation(
    `/projects/${activeProject?.id}/folders/${clickedFolder?.id}`,
    {
      enabled: !!activeProject?.id,
      method: 'PATCH',
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Folder name updated successfully');
        refetch();
        // onComplete();
      }
    }
  );

  const handleCreateTestSuite = async (e) => {
    if (!name) {
      toast.error('Test suite name is required');
      return;
    }
    await createTestSuite({ name });
  };
  const handleUpdateTestName = async (e) => {
    if (!name) {
      toast.error('Test name is required');
      return;
    }
    const testData = {
      name
    };
    if (_folderId) testData['folder_id'] = +_folderId;
    await updateTestName(testData);
  };

  const handleUpdateFolderName = async (e) => {
    if (!name) {
      toast.error('Folder name is required');
      return;
    }

    await updateFolderName({
      name
    });
  };
  useEffect(() => {
    if (useCase === 'folder') {
      setName(clickedFolder.name);
    } else if (useCase === 'test') {
      setName(clickedTest.name);
    }
  }, [clickedFolder, clickedTest]);
  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
          }}>
          <CloseIcon
            sx={{
              fontSize: 25
            }}
          />
        </IconButton>

        <>
          <Typography
            as={'h4'}
            color="primary"
            sx={{
              fontSize: '1.4rem'
            }}>
            {!!suiteID
              ? `Enter Test Suite Name`
              : `Rename ${useCase === 'folder' ? 'folder' : 'test'}`}
          </Typography>
          <Typography
            as={'h4'}
            mb={4}
            sx={{
              fontSize: '0.9rem'
            }}></Typography>
          <Box mb={3}>
            <TextField
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              autoFocus
              size={'small'}
              // placeholder={`Name your ${adjustTitle()}`}
              placeholder={
                !!suiteID
                  ? `Name your test suite`
                  : `Rename ${useCase === 'folder' ? 'folder' : 'test'}`
              }
              fullWidth
            />
          </Box>
        </>
        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!name}
            isLoading={isSavingSuite || isLoading || isUpdatingFolder}
            onClick={() => {
              if (suiteID) {
                handleCreateTestSuite();
                return;
              }
              useCase === 'folder' ? handleUpdateFolderName() : handleUpdateTestName();
            }}
            color={'primary'}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default RenameModal;
