import { Box, Typography } from '@mui/material';
import React from 'react';

const PremiumBadge = () => {
  return (
    <Box
      sx={{
        py: 0.5,
        px: 1,
        backgroundColor: '#1958B8',
        borderRadius: '24px',
        width: 'max-content'
      }}>
      <Typography
        sx={{ color: '#FFF', textTransform: 'capitalize', lineHeight: 1 }}
        fontSize={'0.7rem'}>
        Premium
      </Typography>
    </Box>
  );
};

export default PremiumBadge;
