import * as React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, NavLink, Outlet, useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddTestSvg from '../svg/AddTestSvg';
import Flex from '../base/Flex';
import LowerToolbar from './LowerToolbar';
import { styled } from '@mui/material';
import UserGuideSvg from '../svg/UserGuideSvg';
import { AppRoutes, ProjectRoutes } from '../../routes';
import { CircularImage } from '../personalInformation';
import Logo from '../base/Logo';
import useLogout from '../../hooks/useLogout';
import { useScandiumMutation } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import { ProjectContextProvider, useActiveProject } from '../../store/projectState';
import { FloatingVariableComponentProvider } from '../../store/floatingVariableWindowState';
import { MixpanelPageViewTracker, TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
import CookieConsent from 'react-cookie-consent';
import { FeatureFlagsContextProvider } from '../../store/featureFlagsState';
import { RolesContextProvider } from '../../store/rolesState';
import { TestAutomationProvider } from '../../store/testAutomationState';
import { useRedirect } from '../../store/RedirectContext';
import { useTheme } from '@emotion/react';
import { GlobalVariablesContextProvider } from '../../store/globalVariables';
import { CustomProvider } from 'rsuite';
import { ColorContext } from '../../AppTheme';
import { CollaboratorsContextProvider } from '../../store/membersState';
import { UserContextProvider } from '../../store/userState';

const drawerWidth = 240;
// const navItems = [
//   ProjectRoutes.dashboard,
//   ProjectRoutes.folders,
//   ProjectRoutes.runs,
//   ProjectRoutes.groups,
//   ProjectRoutes.suites,
//   ProjectRoutes.suitesRuns,
//   ProjectRoutes.mobileTesting
//   // ProjectRoutes.apiSuites.children.newSuite
// ];

const webApp_navItems = [
  ProjectRoutes.dashboard,
  ProjectRoutes.folders,
  ProjectRoutes.runs,
  ProjectRoutes.groups,
  ProjectRoutes.suites,
  ProjectRoutes.suitesRuns,
  ProjectRoutes.reports
];

const mobileApp_navItems = [
  ProjectRoutes.dashboard,
  ProjectRoutes.mobileTesting,
  ProjectRoutes.mobileTestRuns,
  ProjectRoutes.reports
];

const nonActiveClassNameDrawer = {
  color: 'black',
  textAlign: 'center',
  textDecoration: 'none',
  width: '100%'
};
const activeClassNameDrawer = {
  color: '#24C3D9',
  textDecoration: 'none',
  textAlign: 'center',
  width: '100%',
  '.button': {
    backgroundColor: 'red'
  }
};

const NoDecorationLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  lineHeight: '1'
}));

const TopMenuNavItem = ({ Icon, label, to, disabled, ...props }) => {
  const theme = useTheme();

  return (
    <NoDecorationLink to={to} {...props} disabled={disabled}>
      <Button
        onClick={() => {
          if (label === 'New Test') {
            trackMixPanel(TRACKING_IDS.NEW_TEST_CLICKED, {
              type: 'web',
              position: 'top nav'
            });
          } else if (label === 'User Guide') {
            trackMixPanel(TRACKING_IDS.USER_GUIDE_CLICKED, {
              position: 'top nav'
            });
          }
        }}
        sx={{
          typography: { sm: 'b7', md: 'body2' },
          textTransform: 'capitalize',
          textAlign: 'center',
          color: theme.palette.text.custom,
          height: '36px'
        }}
        disabled={disabled}
        tabIndex={-1}>
        <Flex columnGap={1}>
          <Icon width={20} height={20} fill="primary" />
          {label}
        </Flex>
      </Button>
    </NoDecorationLink>
  );
};

function ResponsiveAppBar() {
  const theme = useTheme();
  const { mode } = React.useContext(ColorContext);
  const [cookies] = useCookies(['token', 'email', 'name', 'projectType']);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const activeProject = useActiveProject();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { setRedirectPath } = useRedirect();
  const readyOnly = activeProject?.read_only;
  let navigate = useNavigate();
  const logout = useLogout();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { mutateAsync: apiLogout } = useScandiumMutation('/auth/logout', {
    method: 'GET'
  });

  // TODO: move this to an AuthProvider
  if (!cookies.token) {
    setRedirectPath(`${pathname}?${searchParams?.toString() || ''}`);
    return <Navigate to={AppRoutes.login.path} />;
  }

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      await apiLogout();
      logout();
    } catch (error) {
      toast.error('An error occurred, please try again');
    }
  };

  const projectType = activeProject?.type || cookies?.projectType || 'web';

  const navItems = projectType === 'web' ? webApp_navItems : mobileApp_navItems;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Scandium
      </Typography>
      <Divider />
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <ListItem disablePadding>
          <NavLink
            to={AppRoutes.projects.path}
            style={({ isActive }) => (isActive ? activeClassNameDrawer : nonActiveClassNameDrawer)}>
            <ListItemButton
              sx={{
                textAlign: 'center',
                textDecoration: 'none'
              }}>
              <ListItemText primary="Project" />
            </ListItemButton>
          </NavLink>
        </ListItem>
        {navItems.map((item) => (
          <ListItem key={item.name + item.path} disablePadding>
            <NavLink
              to={item.path}
              style={({ isActive }) =>
                isActive ? activeClassNameDrawer : nonActiveClassNameDrawer
              }>
              <ListItemButton
                sx={{
                  textAlign: 'center',
                  // color: '#000',
                  textDecoration: 'none'
                }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <NavLink
            to={AppRoutes.login.path}
            onClick={handleLogout}
            style={({ isActive }) => (isActive ? activeClassNameDrawer : nonActiveClassNameDrawer)}>
            <ListItemButton
              sx={{
                textAlign: 'center',
                color: '#000',
                textDecoration: 'none',
                mt: '5rem'
              }}
              style={{ color: 'red' }}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </NavLink>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <ProjectContextProvider>
      <UserContextProvider>
        <MixpanelPageViewTracker>
          <CollaboratorsContextProvider>
            <TestAutomationProvider>
              <FloatingVariableComponentProvider>
                <RolesContextProvider>
                  <FeatureFlagsContextProvider>
                    <GlobalVariablesContextProvider>
                      <CustomProvider theme={mode || 'light'}>
                        <>
                          <Flex sx={{ display: 'flex' }} id="home">
                            <AppBar component="nav" color="background" elevation={0}>
                              <Toolbar
                                sx={{
                                  background: theme.palette.background.toolbar,
                                  paddingX: { xs: '1.5rem', sm: '2rem', md: '4rem' },
                                  paddingY: { xs: '0.5rem', sm: '0.5rem', md: '0.2rem' },
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}>
                                <Box
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    navigate(`/projects/${activeProject?.id}/dashboard`)
                                  }>
                                  <Logo logoHeight={32} logoWidth={32} fontSize="1.4rem" />
                                </Box>
                                <IconButton
                                  color="inherit"
                                  bgcolor="primary"
                                  aria-label="open drawer"
                                  edge="start"
                                  onClick={handleDrawerToggle}
                                  sx={{ mr: 0, display: { sm: 'none' } }}>
                                  <MenuIcon />
                                </IconButton>
                                <Flex
                                  sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}>
                                  {projectType === 'web' && (
                                    <>
                                      <TopMenuNavItem
                                        Icon={AddTestSvg}
                                        label={'New Test'}
                                        to={'/folders/tests/new'}
                                        disabled={!!readyOnly}
                                      />
                                      <Divider
                                        orientation={'vertical'}
                                        sx={{ mx: 2, height: '1.5rem' }}
                                      />
                                    </>
                                  )}
                                  <TopMenuNavItem
                                    as={'a'}
                                    Icon={UserGuideSvg}
                                    label={'User Guide'}
                                    target={'_blank'}
                                    href={'https://docs.getscandium.com/'}
                                    sx={{ mr: 1 }}
                                  />
                                  <Divider
                                    orientation={'vertical'}
                                    sx={{ mx: 2, height: '1.5rem' }}
                                  />
                                  {/*<NotificationIcon />*/}
                                  <CircularImage
                                    name={cookies.name}
                                    enterprise={true}
                                    style={{
                                      width: '2.5rem',
                                      height: '2.5rem',
                                      marginLeft: '16px'
                                    }}
                                  />
                                </Flex>
                              </Toolbar>
                              <LowerToolbar navItems={navItems} />
                            </AppBar>
                            <Box component="nav">
                              <Drawer
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                  keepMounted: true // Better open performance on mobile.
                                }}
                                sx={{
                                  display: { xs: 'block', sm: 'none' },
                                  '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: drawerWidth
                                  }
                                }}>
                                {drawer}
                              </Drawer>
                            </Box>
                          </Flex>
                          <Outlet />
                        </>
                      </CustomProvider>
                      <CookieConsent
                        location={'bottom'}
                        buttonText={'Allow'}
                        declineButtonText={'Decline'}
                        cookieName={'scandiumCookie'}
                        style={{ background: '#2B373B', paddingRight: '100px' }}
                        buttonStyle={{ color: '#FFFFFF', fontSize: '13px', background: '#1958B8' }}
                        declineButtonStyle={{
                          color: '#000000',
                          fontSize: '13px',
                          background: '#F5F5F5'
                        }}
                        enableDeclineButton
                        expires={90}>
                        Scandium uses cookies to enhance your experience and improve our services.
                        By continuing to use Scandium, you consent to the use of cookies. To learn
                        more about how we handle your data and your privacy rights, please review
                        our{' '}
                        <Link
                          href={'https://getscandium.com/scandium-privacy-policy/'}
                          target={'_blank'}
                          underline={'none'}>
                          <Typography
                            component="span"
                            sx={{
                              cursor: 'pointer',
                              textAlign: 'right',
                              textDecoration: 'underline',
                              typography: { xs: 'caption', sm: 'body2' },
                              lineHeight: '2px',
                              color: '#1958B8'
                            }}>
                            Privacy Policy
                          </Typography>
                        </Link>
                      </CookieConsent>
                    </GlobalVariablesContextProvider>
                  </FeatureFlagsContextProvider>
                </RolesContextProvider>
              </FloatingVariableComponentProvider>
            </TestAutomationProvider>
          </CollaboratorsContextProvider>
        </MixpanelPageViewTracker>
      </UserContextProvider>
    </ProjectContextProvider>
  );
}

export default ResponsiveAppBar;
