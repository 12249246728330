import React, { useEffect } from 'react';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { ColorContext } from '../../../AppTheme';
import { toast } from 'react-toastify';
import { useScandiumRemoteMutation } from '../../../data-layer/remote-execution';
import UserContext from '../../../store/userState';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CopyBlock, dracula } from 'react-code-blocks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CustomScrollbar from './CustomScrollbar';

function CodeExplanation({ data }) {
  if (!data) return;

  return (
    <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '200px', mt: 2 }}>
      <Typography variant={'subtitle1'}>Purpose</Typography>
      <Typography variant={'body2'} mb={2}>{data.purpose}</Typography>
      <Typography variant='body1'>Code</Typography>
      {/* <SyntaxHighlighter
        language={'javascript'}
        wrapLines
        wrapLongLines
        customStyle={{
          maxHeight: '150px',
          overflowY: 'auto'
        }}
        style={dracula}>
        {data.code.replace(/\\n/g, '\n')}
      </SyntaxHighlighter> */}
      <CopyBlock
        text={data.code.replace(/\\n/g, '\n')}
        language={'javascript'}
        showLineNumbers={true}
        theme={dracula}
        codeBlock
      />
      <Typography variant={'body1'} mt={2}>Description</Typography>
      <Typography variant={'body2'}>{data.description}</Typography>
      <Typography variant={'body1'} mt={2}>Line-by-Line Explanations</Typography>
      <Box component={'ul'} sx={{ mt: 2 }}>
        {data.explanations.map((item, index) => (
          <Box component={'li'} key={index} mt={1}>
            <Typography variant={'body2'}>
              <strong>Line {item.line}:</strong> <code>{item.code.replace(/\\n/g, '\n')}</code>
            </Typography>
            <Typography variant={'body2'}>{item.explanation}</Typography>
          </Box>
        ))}
      </Box>
    </CustomScrollbar>
  );
}

const CustomJavascriptStep = ({
  open,
  onClose,
  onComplete,
  addJavascriptStep,
  editMode = false,
  onHandleUpdate,
  initialValue
}) => {
  const { testId, projectId } = useParams();
  const { mode } = React.useContext(ColorContext);
  const [stepTitle, setStepTitle] = React.useState('Execute javascript step');
  const [code, setCode] = React.useState(initialValue || '');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [promptResponse, setPromptResponse] = React.useState(null);
  const { userData } = React.useContext(UserContext);

  React.useEffect(() => {
    if (editMode) {
      setCode(initialValue || '');
    }
  }, [editMode, initialValue]);

  const editorOptions = {
    fontSize: 12,
    formatOnPaste: true,
    renderIndentGuides: true,
    formatOnType: true,
    inlineSuggest: true,
    autoClosingBrackets: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  const handleCloseModal = () => {
    onClose();
    setCode('');
  };

  const handleSaveCustomExpression = () => {
    if (!stepTitle) {
      toast.error('Please provide a title before proceeding');
      return;
    }

    if (!code) {
      toast.error('Please provide a javascript expression before proceeding');
      return;
    }

    editMode ? onHandleUpdate(code) : addJavascriptStep(stepTitle, code);

    onComplete();
    setCode('');
  };

  const {
    mutateAsync: runPrompt,
    isLoading,
  } = useScandiumRemoteMutation(`/js`, {
    headers: {
      'x-api-token': userData?.user?.api_token || ''
    },
    onSuccess: (data) => {
      setPromptResponse(data)
      setSearchQuery('');
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handleScandiumAIPrompt = async () => {
    if (!searchQuery) return;

    await runPrompt({
      test_id: testId,
      project_id: projectId,
      prompt: searchQuery,
      session_id: promptResponse?.data?.session_id || undefined
    });
  };

  const onSearchFieldEnterKeyPress = async (event) => {
    if (event.key === 'Enter') {
      await handleScandiumAIPrompt();
    }
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'550px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem',
            mb: 3
          }}>
          {editMode ? 'Edit Javascript Expression' : 'Add new Javascript step'}
        </Typography>

        {!editMode && (
          <TextField
            id={'custom-js-step-title'}
            variant={'outlined'}
            label={'Step title'}
            fullWidth
            value={stepTitle}
            onChange={(e) => setStepTitle(e.target.value)}
            placeholder={'Search for a test case or folder by name'}
            size={'small'}
            sx={{ mb: 1 }}
          />
        )}

        <Editor
          height={'200px'}
          theme={mode === 'dark' ? 'vs-dark' : 'blackboard'}
          value={code}
          onChange={(value, event) => setCode(value)}
          language={'javascript'}
          options={editorOptions}
        />

        <CodeExplanation data={promptResponse?.data?.data} />

        <Box mt={2}>
          <TextField
            fullWidth
            type={'text'}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={onSearchFieldEnterKeyPress}
            placeholder={'...describe a task and leave ScandiumAI to do the rest'}
            InputProps={{
              sx: { borderRadius: 4, fontSize: 12 },
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading ? (
                    <CircularProgress size={14} />
                  ) : (
                    <IconButton disabled={!searchQuery} onClick={handleScandiumAIPrompt}>
                      <ArrowCircleUpIcon size={14} />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={handleCloseModal}>Close</OutlinedButton>
          <ContainedButton color={'primary'} onClick={handleSaveCustomExpression} autoFocus>
            {editMode ? 'Update' : 'Add'}
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CustomJavascriptStep;
