import React from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreVert } from '@mui/icons-material';
import { CircularProgress, Menu, MenuItem, Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import { toast } from 'react-toastify';

const ActionMenu = React.forwardRef(
  (
    {
      isLoading,
      onDelete,
      folderId,
      testId,
      groupId,
      onMoveToFolder,
      onDuplicate,
      requestTestCaseTagsCreationUploadModal
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const activeProject = useActiveProject();
    const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
      setAnchorEl(null);
      event?.stopPropagation();
    };

    const copyTestIdToClipBoard = () => {
      navigator.clipboard.writeText(testId);

      toast.success('copied to clipboard');
      handleClose();
    };

    const readyOnly = activeProject?.read_only;

    return (
      <div>
        <IconButton
          ref={ref}
          aria-controls={open ? 'action-menu' : undefined}
          aria-haspopup="true"
          disabled={isLoading}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          {isLoading ? <CircularProgress size={18} color={'primary'} /> : <MoreVert />}
        </IconButton>
        <Menu
          id="action-menu"
          elevation={1}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={(e) => e.stopPropagation()}
          PaperProps={{
            style: {
              minWidth: 150
            }
          }}>
          <MenuItem
            as={NavLink}
            sx={{ color: 'inherit' }}
            disabled={!!readyOnly}
            to={
              folderId
                ? `/projects/${activeProject?.id}/folders/${folderId}`
                : groupId
                ? `/projects/${activeProject?.id}/folders/tests/${testId}/edit`
                : `tests/${testId}/edit`
            }>
            {folderId ? 'Open Folder' : 'Edit'}
          </MenuItem>
          {!folderId && (
            <MenuItem
              as={NavLink}
              sx={{ color: 'inherit' }}
              to={`/projects/${activeProject?.id}/test-results/${testId}`}>
              View Runs
            </MenuItem>
          )}
          {folderId && (
            <MenuItem
              as={NavLink}
              sx={{ color: 'inherit' }}
              disabled={!!readyOnly}
              to={`/projects/${activeProject?.id}/folders/${folderId}/edit`}>
              Rename Folder
            </MenuItem>
          )}
          <MenuItem disabled={!!readyOnly} onClick={onMoveToFolder}>
            Move
          </MenuItem>
          {!folderId && (
            <Box>
              <MenuItem onClick={copyTestIdToClipBoard}>Copy test id</MenuItem>
              <MenuItem disabled={!!readyOnly} onClick={() => onDuplicate()}>
                Duplicate
              </MenuItem>
              <MenuItem
                disabled={!!readyOnly}
                onClick={() => requestTestCaseTagsCreationUploadModal()}>
                Tags
              </MenuItem>
            </Box>
          )}
          <MenuItem disabled={!!readyOnly} sx={{ color: 'red' }} onClick={onDelete}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default ActionMenu;
