import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Table,
  tableCellClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';
import { useScandiumMutation } from '../../../data-layer/utils';
import { useActiveProject } from '../../../store/projectState';
import { toast } from 'react-toastify';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: `2px solid ${theme.palette.table.main}`,
    fontWeight: 500
  },
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.table.main}`
  }
}));

const LaunchParamsModal = ({ open, onClose, onComplete, client, mobileAppId, testData }) => {
  const { batchId } = useParams();
  const [rows, setRows] = useState([{ key: '', value: '' }]);
  const activeProject = useActiveProject();

  const handleKeyChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].key = event.target.value;
    setRows(newRows);

    if (event.target.value && index === rows.length - 1) {
      setRows([...rows, { key: '', value: '' }]);
    }
  };

  const handleValueChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].value = event.target.value;
    setRows(newRows);
  };

  const rowStyle = {
    height: '12px',
    outline: 'none',
    fontSize: '12px',
    marginTop: '4px'
  };

  useEffect(() => {
    if (testData?.mobile_app?.query_params?.length) {
      setRows([...testData?.mobile_app?.query_params?.[0]?.params, { key: '', value: '' }]);
    }
  }, [testData]);

  const { mutateAsync: postQueryParams, isLoading: isSavingParams } = useScandiumMutation(
    `/projects/${activeProject?.id}/mobile-tests/apps/query-params`,
    {
      enabled: !!activeProject?.id & !!mobileAppId,
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: (data) => {
        toast.success(data.message);
      }
    }
  );

  const handleQueryParamsUpdate = async (event) => {
    event.preventDefault();
    const filteredQueryParams = [...rows].filter(
      (obj) => obj.key.trim() !== '' && obj.value.trim() !== ''
    );

    if (!filteredQueryParams.length || !mobileAppId) return;

    const objectOfObjects = filteredQueryParams.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

    if (client) {
      await client.setConfig({
        params: JSON.stringify(objectOfObjects)
      });
    }

    await postQueryParams({
      mobile_app_id: mobileAppId?.toString(),
      params: filteredQueryParams
    });
  };

  return (
    <CustomModal open={open}>
      <Box
        component={'form'}
        onSubmit={handleQueryParamsUpdate}
        py={1}
        px={2}
        width={'80vw'}
        maxWidth={'480px'}
        position={'relative'}>
        <Typography as={'h4'} color="primary" fontWeight={600} sx={{ fontSize: '1.4rem', mb: 0.5 }}>
          Launch Params
        </Typography>
        <Typography variant={'body1'} mb={2}>
          With Launch Params, you can pass custom data to your mobile apps while running. It can be
          useful to load custom content, skip onboarding, auto-login a specified user, or custom
          tracking.
        </Typography>

        <CustomScrollbar sx={{ overflowY: 'scroll', maxHeight: '40vh' }}>
          <TableContainer>
            <Table sx={{ width: '100%' }} size="small" aria-label={'a Query Param Table'}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Key</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>
                      <TextField
                        fullWidth
                        size={'small'}
                        variant={'standard'}
                        InputProps={{
                          disableUnderline: true
                        }}
                        value={row.key}
                        onChange={(event) => handleKeyChange(event, index)}
                        inputProps={{
                          readOnly: !!batchId,
                          style: { ...rowStyle }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        fullWidth
                        size={'small'}
                        variant={'standard'}
                        InputProps={{
                          disableUnderline: true
                        }}
                        value={row.value}
                        onChange={(event) => handleValueChange(event, index)}
                        inputProps={{
                          readOnly: !!batchId,
                          style: { ...rowStyle }
                        }}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomScrollbar>

        <Flex sx={{ mt: 3 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            disabled={!!isSavingParams}
            isLoading={isSavingParams}
            type={'submit'}
            color={'primary'}
            sx={{
              '&:disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'all !important'
              }
            }}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default LaunchParamsModal;
