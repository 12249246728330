import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  TextField,
  Tooltip,
  Divider,
  Radio,
  RadioGroup
} from '@mui/material';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Flex from '../../../components/base/Flex';
import CustomModal from '../../../components/base/CustomModal';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@emotion/react';

const BulkSettingsModal = ({ open, onClose, onComplete, handleBulkEventsUpdate }) => {
  const theme = useTheme();

  return (
    <CustomModal open={open}>
      <Box py={1} px={2} width={'80vw'} maxWidth={'500px'} position={'relative'}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '6px',
            right: '16px'
          }}>
          <CloseIcon sx={{ fontSize: 25 }} />
        </IconButton>

        <Typography as={'h4'} color={theme.palette.text.custom} mb={2} sx={{ fontSize: '1.5rem' }}>
          Bulk Edit Test Steps
        </Typography>

        <Typography variant={'body2'} color={theme.palette.text.black_grey} mb={1}>
          Apply settings to multiple selected test case steps at once. Choose the desired
          configuration, and it will be applied to all the selected steps, saving you time and
          effort.
        </Typography>

        <Divider sx={{ mb: 1 }} />

        <FormControl>
          <FormLabel
            id="Preconditions-controlled-radio-buttons-group"
            sx={{
              fontWeight: 'bold',
              color: theme.palette.text.custom
            }}>
            Wait Mode
          </FormLabel>
          <Typography textAlign={'left'} sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
            Wait for
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                size={'small'}
                onChange={(e) => handleBulkEventsUpdate({ mustBeVisible: e.target.checked })}
                sx={{
                  py: 0.5,
                  '&.Mui-checked': {
                    color: theme.palette.radio.main
                  }
                }}
                // checked={!!eventMeta.mustBeVisible}
                inputProps={{ 'arial-label': 'Element should be visible' }}
              />
            }
            label={
              <Typography
                fontSize={13}
                sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                Element to be visible
                <Tooltip title="This specifies that the target element of this step must be visible i.e not hidden on page">
                  <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                </Tooltip>
              </Typography>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                size={'small'}
                sx={{
                  py: 0.5,
                  '&.Mui-checked': {
                    color: theme.palette.radio.main
                  }
                }}
                onChange={(e) => handleBulkEventsUpdate({ mustBeActionable: e.target.checked })}
                // checked={!!eventMeta.mustBeActionable}
                inputProps={{ 'arial-label': 'Element should be visible' }}
              />
            }
            label={
              <Typography
                fontSize={12}
                sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                Element to be actionable
                <Tooltip title="This specifies that the target element of this step must be actionable. An actionable element is visible, not disabled, and not ‘readonly’.">
                  <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                </Tooltip>
              </Typography>
            }
          />
        </FormControl>

        <Divider sx={{ my: 1 }} />

        <FormControl>
          <FormLabel
            id="Preconditions-controlled-radio-buttons-group"
            sx={{
              fontWeight: 'bold',
              color: theme.palette.text.custom
            }}>
            Failure Mode
          </FormLabel>
          <Typography textAlign={'left'} sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
            When this step fails
          </Typography>
          <RadioGroup
            aria-labelledby="Preconditions-controlled-radio-buttons-group"
            name="Preconditions-controlled-radio-buttons-group"
            // value={eventMeta.onError}
            onChange={(event) => handleBulkEventsUpdate({ onError: event.target.value })}>
            <FormControlLabel
              value="fail"
              control={
                <Radio
                  sx={{
                    py: 0.5,
                    '&.Mui-checked': {
                      color: theme.palette.radio.main
                    }
                  }}
                  size="small"
                />
              }
              label={
                <Typography
                  fontSize={13}
                  sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  Fail test immediately
                  <Tooltip title="When this step fails, the test is marked as failed, and subsequent steps are not executed">
                    <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                  </Tooltip>
                </Typography>
              }
            />
            <FormControlLabel
              value="continue"
              control={
                <Radio
                  sx={{
                    py: 0.5,
                    '&.Mui-checked': {
                      color: theme.palette.radio.main
                    }
                  }}
                  size="small"
                />
              }
              label={
                <Typography
                  fontSize={13}
                  sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  Fail and continue
                  <Tooltip title="When this step fails, the test is marked as failed, but subsequent steps are executed.">
                    <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                  </Tooltip>
                </Typography>
              }
            />
            <FormControlLabel
              value="ignore"
              control={
                <Radio
                  sx={{
                    py: 0.5,
                    '&.Mui-checked': {
                      color: theme.palette.radio.main
                    }
                  }}
                  size="small"
                />
              }
              label={
                <Typography
                  fontSize={13}
                  sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  Ignore failure
                  <Tooltip title="The 'Ignore failure' mode will execute all test cases even when the extension report a failure">
                    <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                  </Tooltip>
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>

        <Divider sx={{ mt: 1 }} />

        <FormControlLabel
          control={
            <Checkbox
              size={'small'}
              sx={{
                '&.Mui-checked': {
                  color: theme.palette.radio.main
                }
              }}
              onChange={(e) => handleBulkEventsUpdate({ scrollIntoView: e.target.checked }, true)}
              //   checked={!!(isGroup ? event.meta : event)?.element?.scrollIntoView}
              inputProps={{ 'arial-label': 'Scroll into view' }}
            />
          }
          label="Scroll into view"
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              sx={{
                '&.Mui-checked': {
                  color: theme.palette.radio.main
                }
              }}
              onChange={(e) => handleBulkEventsUpdate({ use_native_event: e.target.checked }, true)}
              //   checked={!!(isGroup ? event.meta : event)?.element?.use_native_event}
              inputProps={{ 'arial-label': 'Scroll into view' }}
            />
          }
          label="Use native event"
        />

        <Divider sx={{ my: 1 }} />

        <Flex>
          <Flex sx={{ flexDirection: 'column', flex: 0.5, alignItems: 'flex-start' }}>
            <Typography
              variant={'body2'}
              sx={{
                textAlign: 'left',
                color: theme.palette.text.black_grey,
                fontWeight: 'medium'
              }}>
              Pre-Step delay (ms)
            </Typography>
            <TextField
              size="small"
              fullWidth
              type={'number'}
              //   value={(isGroup ? event.meta : event)?.preStepDelay}
              sx={{
                height: { xs: 1, sm: 1 },
                mt: { xs: 1, sm: 1 },
                mb: { x: 1, sm: 2 }
              }}
              onChange={(e) => handleBulkEventsUpdate({ preStepDelay: e.target.value })}
            />
          </Flex>
          <Flex sx={{ flexDirection: 'column', flex: 0.5, alignItems: 'flex-start' }}>
            <Typography
              variant={'body2'}
              sx={{
                textAlign: 'left',
                color: theme.palette.text.black_grey,
                fontWeight: 'medium'
              }}>
              Step timeout (ms)
            </Typography>
            <TextField
              size="small"
              fullWidth
              type={'number'}
              //   value={(isGroup ? event.meta : event)?.preStepDelay}
              sx={{
                height: { xs: 1, sm: 1 },
                mt: { xs: 1, sm: 1 },
                mb: { x: 1, sm: 2 }
              }}
              onChange={(e) => handleBulkEventsUpdate({ stepTimeout: e.target.value })}
            />
          </Flex>
        </Flex>

        <Divider sx={{ my: 1 }} />

        {/* <Flex sx={{ py: 1 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton
            isLoading={isLoading}
            disabled={!title}
            onClick={handleGroupCreation}
            color={'primary'}
            autoFocus>
            Apply
          </ContainedButton>
        </Flex> */}
      </Box>
    </CustomModal>
  );
};

export default BulkSettingsModal;
