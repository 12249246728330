import React, { useRef, useState } from 'react';
import { NavLink, useParams, Link, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useDebounce } from 'use-debounce';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import jsFileDownload from 'js-file-download';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip
} from '@mui/material';
import Flex from '../../components/base/Flex';
import ProjectSvg from '../../components/svg/ProjectSvg';
import { rgba } from '../../utils/colors';
import { useActiveProject } from '../../store/projectState';
import {
  useDeleteFolder,
  useFetchFolders,
  useFetchTests
} from '../../data-layer/project-management';
import { formatDate } from '../../utils/formateDate';
import InviteCollaboratorsCta from '../../components/InviteCollaboratorsCta';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import PageLoader from '../../components/PageLoader';
import EmptyState from '../../components/base/EmptyState';
import OutlinedButton from '../../components/base/OutlinedButton';
import ErrorState from '../../components/base/ErrorState';
import ActionMenu from './components/ActionMenu';
import { useDeleteTest } from '../../data-layer/test-management';
import { toast } from 'react-toastify';
import { useConfirmDialog } from '../../components/base/ConfirmDialog';
import useAwaitModal from '../../hooks/useAwaitModal';
import MoveToFolder from './components/MoveToFolder';
import TestCaseReports from './components/TestCaseReports';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import { getCurrentDate } from '../SuitesRuns/utils';
import usePagination from '../../hooks/usePagination';
import ManualTestCasesUploadModal from './components/ManualTestCasesUploadModal';
import ContainedButton from '../../components/base/ContainedButton';
import PassedRunSvg from '../../components/svg/PassedRunSvg';
import FailedRunSvg from '../../components/svg/FailedRunSvg';
import { Badge } from '../TestPage/components/TestOverviewModal';
import RunningSvg from '../../components/svg/RunningSvg';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
import SearchFilterMenu from './components/SearchFilterMenu';
import CreateTestCaseTags from './components/CreateTestCaseTags';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    borderBottom: `2px solid ${theme.palette.table.mobileOutline}`,
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    }
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.table.mobileOutline}`,
    padding: '8px 0px',
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    },
    '&:hover': {
      bgcolor: 'rgba(36, 195, 217, 0.9)'
    },
    '.cell-content-wrapper': {
      padding: '16px',
      backgroundColor: 'rgba(36, 195, 217, 0.1)',
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none'
    },

    '&:first-child': {
      minWidth: '250px',
      '.cell-content-wrapper': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }
    },

    '&:last-child': {
      '.cell-content-wrapper': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 2
  }
}));

// testCases row
export const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    borderBottom: `2px solid ${theme.palette.table.mobileOutline}`,
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    }
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.table.mobileOutline}`,
    padding: '8px 0px',
    '&:not(:first-of-type)': {
      maxWidth: '200px'
    },
    '.cell-content-wrapper': {
      padding: '16px',
      backgroundColor: theme.palette.background.testCaseList,
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: '64px',
      display: 'flex',
      alignItems: 'center'
    },

    '&:first-child': {
      minWidth: '250px',
      '.cell-content-wrapper': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }
    },

    '&:last-child': {
      '.cell-content-wrapper': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px'
      }
    }
  }
}));

const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 2
  }
}));

export const getTestStatus = (lastRun) => {
  if (!lastRun) return;
  return lastRun.status === 'error' ? 'failed' : 'passed';
};

export const StatusTag = ({ status }) => {
  const theme = useTheme();
  const colors = {
    running: rgba('#FFEA00', 20),
    passed: rgba('#00CA08', 19),
    failed: rgba('#EF1B1B', 20)
  };

  return (
    <Box
      sx={{
        py: 1.5,
        px: 2,
        backgroundColor: colors[status],
        borderRadius: '24px',
        width: 'max-content'
      }}>
      <Typography
        sx={{
          color: theme.palette.text.resultTableText,
          textTransform: 'capitalize',
          lineHeight: 1
        }}
        fontSize={'0.8rem'}>
        {status}
      </Typography>
    </Box>
  );
};

const LastRunStatus = (status) => {
  const _status = status?.status?.status;
  if (!_status) return null;
  const statusMap = {
    success: <PassedRunSvg />,
    error: <FailedRunSvg />,
    running: <RunningSvg />,
    aborted: <DoDisturbIcon color={'error'} />
  };

  return statusMap[_status] || _status;
};

const TestRow = ({ test, refetchTest, requestTestCaseTagsCreationUploadModal }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const { projectId, groupId } = useParams();
  const activeProject = useActiveProject();
  const menuRef = useRef(null);
  const { mutateAsync: deleteTest, isLoading: isDeleting } = useDeleteTest({
    projectId,
    testId: test.id,
    onSuccess: () => {
      refetchTest();
      toast.success('Test deleted successfully');
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const {
    requestConfirm: requestConfirmDuplicate,
    ConfirmationDialog: ConfirmationDuplicateDialog
  } = useConfirmDialog();

  const [
    requestMoveToFolderModal,
    {
      open: moveToFolderOpen,
      onClose: onCloseMoveToFolder,
      onComplete: completeMoveToFolderModal,
      ...otherProps
    }
  ] = useAwaitModal();

  const handleDelete = async () => {
    if (await requestConfirm()) return await deleteTest();
  };

  const { mutateAsync: duplicateTest, isLoading: isDuplicating } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/${test.id}/duplicate`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchTest();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleDuplicateTest = async () => {
    if (await requestConfirmDuplicate()) return await duplicateTest();
  };

  return (
    <StyledTableRow2
      ref={ref}
      component={Link}
      sx={{ textDecoration: 'none' }}
      to={
        groupId
          ? `/projects/${activeProject?.id}/folders/tests/${test.id}/edit`
          : `tests/${test.id}/edit`
      }>
      <StyledTableCell2 component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <ProjectSvg width={24} height={24} fill={theme.palette.svg.primary} color={'primary'} />
          <Flex alignItems="flex-start" sx={{ flexDirection: 'column' }}>
            <Tooltip title={test.name || ''}>
              <Typography
                fontWeight={500}
                color={theme.palette.text.black_white}
                sx={{
                  lineHeight: 1,
                  mt: '4px',
                  display: 'inline-block',
                  maxWidth: '300px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}>
                {test.name || ''}
              </Typography>
            </Tooltip>
            <Typography
              sx={{
                lineHeight: 1,
                mt: '4px',
                display: 'inline-block',
                fontSize: '0.85rem',
                maxWidth: '300px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
              {test.description || ''}
            </Typography>
          </Flex>
        </Flex>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>{test.creator?.name}</Box>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>{formatDate(test.created_at)}</Box>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>
          <Typography
            sx={{
              lineHeight: 1,
              display: 'inline-block',
              fontSize: '0.85rem',
              maxWidth: '120px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}>
            {test.tags?.map((tag) => tag.name.en).join(', ') || ''}
          </Typography>
        </Box>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>{<Badge status={test.status || ''} />}</Box>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Flex className={'cell-content-wrapper'} justifyContent={'center'}>
          <Tooltip title={test.last_run_result?.status || ''}>
            <IconButton>
              <LastRunStatus status={test.last_run_result || ''} />
            </IconButton>
          </Tooltip>
        </Flex>
      </StyledTableCell2>
      <StyledTableCell2 align="right">
        <Box className={'cell-content-wrapper'}>
          <ActionMenu
            isLoading={isDeleting || isDuplicating}
            onDelete={handleDelete}
            testId={test.id}
            ref={menuRef}
            groupId={groupId}
            onMoveToFolder={() => requestMoveToFolderModal({ moveTest: true })}
            onDuplicate={handleDuplicateTest}
            requestTestCaseTagsCreationUploadModal={requestTestCaseTagsCreationUploadModal}
          />
        </Box>
      </StyledTableCell2>
      <ConfirmationDialog
        title={'Are you sure you want to delete this test'}
        description={'This action is not reversible'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <MoveToFolder
        open={moveToFolderOpen}
        onClose={onCloseMoveToFolder}
        testId={test.id}
        refetch={refetchTest}
        {...otherProps}
      />
      <ConfirmationDuplicateDialog
        title={'Are you sure you want to duplicate this test case?'}
        description={
          'By duplicating this test case, a new test case will be created. The new test case will be identical to the original, and you can edit it as needed.'
        }
        confirmLabel={'Duplicate'}
      />
    </StyledTableRow2>
  );
};

const FolderRow = ({ folder, refetchFolders }) => {
  const { projectId } = useParams();
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const menuRef = useRef(null);
  const activeProject = useActiveProject();
  const theme = useTheme();

  const { mutateAsync: deleteFolder, isLoading: isDeleting } = useDeleteFolder(projectId, {
    folderId: folder.id,
    onSuccess: () => {
      refetchFolders();
      toast.success('Folder deleted successfully');
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handleDeleteFolder = async () => {
    if (await requestConfirm()) return await deleteFolder();
  };

  const [
    requestMoveToFolderModal,
    {
      open: moveToFolderOpen,
      onClose: onCloseMoveToFolder,
      onComplete: completeMoveToFolderModal,
      ...otherProps
    }
  ] = useAwaitModal();

  return (
    <StyledTableRow
      component={Link}
      sx={{ textDecoration: 'none' }}
      to={`/projects/${activeProject?.id}/folders/${folder.id}?page=1`}>
      <StyledTableCell component="th" scope="row">
        <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
          <FolderIcon sx={{ color: theme.palette.svg.main }} />
          <Typography
            fontWeight={500}
            sx={{
              lineHeight: 1,
              mt: '4px',
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}>
            {folder.name}
          </Typography>
        </Flex>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{folder.creator?.name || ''}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{formatDate(folder.created_at)}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>{''}</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          <ActionMenu
            folderId={folder.id}
            isLoading={isDeleting}
            onDelete={handleDeleteFolder}
            ref={menuRef}
            onMoveToFolder={() => requestMoveToFolderModal({ moveTest: false })}
          />
        </Box>
      </StyledTableCell>
      <ConfirmationDialog
        title={'Are you sure you want to delete this folder?'}
        description={'All tests within the folder will be deleted as well'}
        confirmLabel={'Delete'}
        confirmColor={'error'}
      />
      <MoveToFolder
        open={moveToFolderOpen}
        onClose={onCloseMoveToFolder}
        folderId={folder.id}
        refetch={refetchFolders}
        folderItem={folder}
        {...otherProps}
      />
    </StyledTableRow>
  );
};

const ProjectFolders = () => {
  useDocumentTitle('Project Folders');
  const activeProject = useActiveProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();
  const { folderId, groupId } = useParams();
  const [format, setFormat] = useState('excel');
  const theme = useTheme();

  const readyOnly = activeProject?.read_only;

  const [
    requestManualTestUploadModal,
    {
      open: manualTestUploadOpen,
      onClose: onCloseManualTestUpload,
      onComplete: completeManualTestUpload
    }
  ] = useAwaitModal();

  const [
    requestTestCaseTagsCreationUploadModal,
    {
      open: testCaseTagsCreationOpen,
      onClose: onCloseTestCaseTagsCreation,
      onComplete: completeTestCaseTagsCreation,
      ...otherProps
    }
  ] = useAwaitModal();

  const fileExtensionMap = {
    excel: 'xlsx',
    csv: 'csv',
    pdf: 'pdf'
  };

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    const params = new URLSearchParams(searchParams);

    if (!newQuery) {
      params.delete('query');
    } else {
      params.set('query', newQuery);
    }

    setSearchParams(params, { replace: true });
  };

  const {
    data: folderRows,
    isLoading: isProjectFoldersLoading,
    error: foldersError,
    refetch: refetchFolders
  } = useFetchFolders(activeProject?.id, {
    folderId,
    enabled: !!activeProject?.id && !groupId,
    select: (data) =>
      folderId ? data.data?.subdirectories : data.data?.filter((folder) => !folder.parent_id)
  });

  const {
    data: testsResponse,
    isLoading: isTestsLoading,
    error: testsError,
    refetch: refetchTests
  } = useFetchTests(activeProject?.id, folderId, {
    enabled: !!activeProject?.id,
    params: {
      page: activePage,
      limit: pageLimit,
      users: searchParams.get('users')?.replace(/-/g, ',') || undefined,
      tags: searchParams.get('tags')?.replace(/-/g, ',') || undefined,
      status: searchParams.get('status') || undefined,
      search: debouncedQuery || undefined,
      group_id: groupId || undefined
    }
  });

  const { data: tags = [], refetch: refetchTags } = useScandiumQuery(`/tags`, {
    enabled: !!activeProject?.id,
    params: {
      project_id: activeProject?.id,
      type: 'test_case'
    },
    select: (data) => data.data
  });

  const userTimezone = DateTime.local().zoneName;

  const { isLoading: isExportTestCases, refetch: exportTestCases } = useScandiumQuery(
    `/projects/${activeProject?.id}/reports/test-cases?format=${format}`,
    {
      json: false,
      enabled: false,
      headers: {
        'SCANDIUM-USER-TIMEZONE': userTimezone,
        mode: 'no-cors'
      },
      onSuccess: (data) => {
        jsFileDownload(
          data,
          `Scandium test case report ${getCurrentDate()}.${fileExtensionMap[format]}`
        );
        toast.success('Your report is being downloaded');

        trackMixPanel(TRACKING_IDS.DATA_EXPORTED, {
          type: 'testcases'
        });
      },
      onError: (data) => {
        toast.error(data.message);
      }
    }
  );

  // select the tests data from testResponse so we don't keep doing `testsResponse?.data...`
  const tests = testsResponse?.data;

  const isLoading = isProjectFoldersLoading || isTestsLoading || !activeProject;

  const filteredTestCases = (tests || []).filter((test) =>
    test.name?.toLowerCase().includes(searchTerm)
  );

  const filteredFolders = (folderRows || []).filter((folder) =>
    folder.name?.toLowerCase().includes(searchTerm)
  );

  const allEntries = [...filteredFolders, ...filteredTestCases];

  return (
    <Box
      sx={{
        mt: -2
      }}>
      <Flex justifyContent={'space-between'} sx={{ my: 2.5, flexWrap: 'wrap' }} columnGap={1}>
        <Typography
          fontWeight={'600'}
          as={'h2'}
          fontSize={'1.2rem'}
          color={theme.palette.text.custom}>
          {`Test Cases: ${testsResponse?.meta?.total || 0}`}
        </Typography>
        <Flex sx={{ flexWrap: 'wrap' }} columnGap={2}>
          <SearchFilterMenu
            handleSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            tags={tags}
          />

          <ContainedButton
            startIcon={<CloudUploadIcon sx={{ color: theme.palette.svg.main }} />}
            disabled={!!readyOnly}
            onClick={() => requestManualTestUploadModal()}
            sx={{
              borderRadius: '0.4rem',
              bgcolor: 'primary',
              py: { xs: '0.2rem', sm: '0.3rem' },
              px: 1.5,
              minHeight: '28px'
            }}>
            Import
          </ContainedButton>

          <InviteCollaboratorsCta />
        </Flex>
      </Flex>
      <Box>
        <Box>
          <TableContainer
            component={Paper}
            elevation={1}
            sx={{
              boxShadow: 1,
              mb: 2,
              position: 'relative',
              zIndex: 100
            }}>
            <Table sx={{ width: '100%', minWidth: 700, zIndex: 100 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Created By</StyledTableCell>
                  <StyledTableCell>Created On</StyledTableCell>
                  <StyledTableCell>Tags</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align={'center'}>Last Run</StyledTableCell>
                  <StyledTableCell align={'right'}>
                    <TestCaseReports
                      format={format}
                      setFormat={setFormat}
                      isExportTestCases={isExportTestCases}
                      disabled={!allEntries.length || isExportTestCases}
                      onExportTestCase={exportTestCases}
                    />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activePage === 1 &&
                  filteredFolders?.map((row) => (
                    <FolderRow key={row.id} folder={row} refetchFolders={refetchFolders} />
                  ))}
                {tests?.map((row) => (
                  <TestRow
                    key={row.id}
                    refetchTest={refetchTests}
                    test={row}
                    requestTestCaseTagsCreationUploadModal={() =>
                      requestTestCaseTagsCreationUploadModal({
                        testTags: row.tags.map((tag) => tag.name.en) || [],
                        testId: row.id
                      })
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {isLoading && <PageLoader height={'100px'} />}
          {!isLoading &&
            (foldersError || testsError
              ? !allEntries.length && <ErrorState error={foldersError || testsError} />
              : !allEntries.length && (
                  <EmptyState
                    title={
                      !folderRows?.length && !tests?.length
                        ? "It's lonely here at the moment"
                        : 'There are no matching results'
                    }
                    description={
                      !folderRows?.length && !tests?.length
                        ? `Why not create a few tests or folders?`
                        : 'Try searching for something else, or create a new test'
                    }
                    cta={
                      <Flex>
                        <OutlinedButton disabled={!!readyOnly} component={NavLink} to={'tests/new'}>
                          Create Test
                        </OutlinedButton>
                      </Flex>
                    }
                  />
                ))}
        </Box>
      </Box>

      {testsResponse?.meta.last_page > 1 && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            mb: { xs: 6, sm: 8 }
          }}>
          <Pagination
            count={testsResponse?.meta.last_page}
            page={activePage}
            color="secondary"
            onChange={handlePageChange}
            size={'small'}
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#24C3D9',
                color: '#ffffff'
              },
              '& .MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#1958B8',
                color: '#fff'
              }
            }}
          />

          <FormControl sx={{ mr: 5 }}>
            <InputLabel id="go-to-page-label">Tests per page</InputLabel>
            <Select
              labelId={'go-to-page-label'}
              value={pageLimit}
              label={'Tests per page'}
              size={'small'}
              MenuProps={{
                elevation: 1
              }}
              onChange={handleSelectPageChange}>
              {[...Array(testsResponse?.meta.last_page).keys()].map((page, i) => (
                <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                  {(page + 1) * DEFAULT_PAGE_SIZE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <ManualTestCasesUploadModal
        open={manualTestUploadOpen}
        onClose={onCloseManualTestUpload}
        onComplete={completeManualTestUpload}
        refetchTests={refetchTests}
      />

      <CreateTestCaseTags
        open={testCaseTagsCreationOpen}
        onClose={onCloseTestCaseTagsCreation}
        onComplete={completeTestCaseTagsCreation}
        refetchTests={refetchTests}
        tags={tags}
        refetchTags={refetchTags}
        {...otherProps}
      />
    </Box>
  );
};

export default ProjectFolders;
