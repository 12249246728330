const checkValidJson = (text) => {
  if (
    /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  ) {
    return true;
  } else {
    return false;
  }
};
export const cleanJson = (jsonString) => {
  try {
    const parsedJson = JSON.parse(jsonString);
    const cleanedJson = JSON.stringify(parsedJson);
    return cleanedJson;
  } catch (err) {
    return null;
  }
};

export const vJson = (json) => {
  try {
    const clean = json.replace(/\n/g, '').replace(/\s/g, '');
    const jsonObj = JSON.parse(clean);
    return jsonObj;
  } catch (err) {
    return null;
  }
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const getJSONResponseFields = (data) => {
  const fieldsSet = new Set();
  const extractFields = (obj, currentPath = []) => {
    for (const key in obj) {
      fieldsSet.add([...currentPath, key].join('.'));

      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          extractFields(obj[key][i], [...currentPath, key, i]);
        }
      } else if (typeof obj[key] === 'object') {
        extractFields(obj[key], [...currentPath, key]);
      }
    }
  };
  if (typeof data === 'object') {
    extractFields(data);
    let sortedFields = [...fieldsSet];
    // solves for name.1 to be name[1]
    sortedFields = sortedFields.map((item) => {
      const eachField = item.split('.');
      const newValue = eachField.map((value) => {
        if (!isNaN(value)) {
          return `[${value}]`;
        } else {
          return value;
        }
      });
      const result = newValue.join('.').replace(/\.\[/g, '[');
      return result;
    });
    return sortedFields;
  } else {
    return null;
  }
};
export const getJSONResponseKeys = (obj) => {
  const keysSet = new Set();
  function extractKeys(obj) {
    for (const key in obj) {
      keysSet.add(key);

      if (typeof obj[key] === 'object') {
        extractKeys(obj[key]);
      }
    }
  }
  if (typeof obj === 'object') {
    extractKeys(obj);
    return Array.from(keysSet).filter((key) => {
      return isNaN(key);
    });
  } else {
    return null; // I could further handle non-object input if needed
  }
};

export const extractPathVariablesKey = (url) => {
  // const matches = url.match(/\/:({{\w+}}|\w+)/g);
  // const matches = url.match(/\/:(\w+)/g);
  const matches = url.match(/\/:([^/]+)/g);
  const pathVariableKeys = matches ? matches.map((str) => str.slice(2)) : [];
  return pathVariableKeys;
};
export const swapPathVariablesInURL = (url, pathVariables) => {
  let newURL = url;
  if (!!pathVariables.length) {
    pathVariables.forEach(({ key, value }) => {
      if (!!value) {
        const regex = new RegExp(`:${key}`, 'g');
        newURL = url.replace(regex, value);
      }
    });
  }
  // console.log(newURL);
  return newURL;
};
