import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { toast } from 'react-toastify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useScandiumQuery } from '../../../data-layer/utils';

export const SelectDropdown = ({
  value,
  options,
  onChange,
  isLoading = false,
  label,
  labelId,
  ...otherProps
}) => {
  return (
    <FormControl fullWidth size={'small'}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={'Trello Board'}
        value={value}
        label={label}
        onChange={onChange}
        IconComponent={(defaultProps) =>
          isLoading ? (
            <CircularProgress size={16} color={'secondary'} {...defaultProps} />
          ) : (
            <ArrowDropDownIcon {...defaultProps} />
          )
        }
        renderValue={(selectedValue) => {
          return selectedValue ? selectedValue?.name || selectedValue?.login : '';
        }}
        MenuProps={{
          elevation: 1
        }}
        {...otherProps}>
        {options?.map((board) => (
          <MenuItem key={board.id} value={board}>
            {board?.name || board?.login}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const GithubConnectModal = ({
  open,
  onClose,
  onComplete,
  updateChannelData,
  successChannels,
  errorChannels,
  activeTab,
  isGithubTokenSuccess,
  showGithubSettings,
  handlePostChannels
}) => {
  const activeChannel = activeTab === 0 ? successChannels : errorChannels;
  const githubChannel = activeChannel.find((channel) => channel.channel === 'github');
  const activeOrg = githubChannel.channel_details.data;

  const [organizations, setOrganizations] = useState([]);
  const [organizationRepositories, setOrganizationRepositories] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedRepository, setSelectedRepository] = useState(null);

  const handleOrganizationChange = (event) => {
    setSelectedOrganization(event.target.value);
  };

  const handleRepositoryChange = (event) => {
    setSelectedRepository(event.target.value);
  };

  useEffect(() => {
    const matchingOrgs = organizations?.find((org) => org.id === activeOrg?.org_id);
    const matchingRepo = organizationRepositories?.find(
      (repo) => repo.name === activeOrg?.repo_name
    );
    setSelectedRepository(matchingRepo || organizationRepositories?.[0]);
    setSelectedOrganization(matchingOrgs || organizations?.[0]);
  }, [activeChannel, githubChannel]);

  const handleSaveTrelloData = async () => {
    if (!selectedOrganization || !selectedRepository) {
      toast.error('All fields are required');
      return;
    }

    updateChannelData(
      'github',
      {
        repo_name: selectedRepository?.full_name,
        org_id: selectedOrganization?.id
      },
      handlePostChannels
    );

    onClose();
  };

  const { isLoading: isFetchingOrganizations } = useScandiumQuery('/third-parties/github/orgs', {
    enabled: !!showGithubSettings || isGithubTokenSuccess,
    onSuccess: (data) => {
      const matchingOrganization = data.data.find((org) => org.id === activeOrg?.org_id);
      setSelectedOrganization(matchingOrganization || data?.data?.[0]);
      setOrganizations(data.data);
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const { isLoading: isFetchingOrganizationRepository } = useScandiumQuery(
    `/third-parties/github/orgs/${selectedOrganization?.id}/repos`,
    {
      enabled: !!selectedOrganization?.id,
      onSuccess: (data) => {
        const matchingRepo = data.data.find((repo) => repo.name === activeOrg?.repo_name);
        setSelectedRepository(matchingRepo || data?.data?.[0]);
        setOrganizationRepositories(data.data);
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'500px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem'
          }}>
          Connect Your Github Account
        </Typography>

        <Typography
          as={'h4'}
          mb={4}
          sx={{
            fontSize: '0.9rem'
          }}>
          The modal allows you to choose a Github repository. When a test fails or passes, a card
          will be created in the selected repo to track the outcome.
        </Typography>

        <Box>
          <SelectDropdown
            options={organizations}
            isLoading={isFetchingOrganizations}
            onChange={handleOrganizationChange}
            value={selectedOrganization}
            label={'Select Organization'}
            labelId={'Repository label'}
            sx={{ mb: 2 }}
          />

          <SelectDropdown
            options={organizationRepositories}
            isLoading={isFetchingOrganizationRepository}
            onChange={handleRepositoryChange}
            value={selectedRepository}
            label={'Select Organization Repository'}
            labelId={'Repository label'}
            sx={{ mb: 2 }}
          />
        </Box>

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={onClose}>Cancel</OutlinedButton>
          <ContainedButton onClick={handleSaveTrelloData} color={'primary'} autoFocus>
            connect
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default GithubConnectModal;
