import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { useActiveProject } from '../../store/projectState';
import ContainedButton from '../../components/base/ContainedButton';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumMutation, useScandiumQuery } from '../../data-layer/utils';
import BackButton from '../../components/base/BackButton';
import { Checkbox, FormControlLabel, Radio, RadioGroup, Tooltip, useTheme } from '@mui/material';
import { Divider, FormControl, FormLabel } from '@mui/material/node';
import Flex from '../../components/base/Flex';

function DefaultSettings() {
  useDocumentTitle('Scandium | Default Project Settings');
  let navigate = useNavigate();
  const activeProject = useActiveProject();

  const [preStepDelay, setPreStepDelay] = useState('');
  const [stepTimeout, setStepTimeout] = useState('');
  const [failureMode, setFailureMode] = useState('');
  const [startingUrl, setStartingUrl] = useState('');
  const [nativeEvents, setUseNativeEvents] = useState(false);
  const theme = useTheme();

  const { mutateAsync: saveProjectDefaults, isLoading } = useScandiumMutation(
    `/projects/${activeProject?.id}/settings`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
      },
      onError: (data) => {
        toast.error(data.message);
      }
    }
  );

  const { isLoading: isFetchingProjectSettings } = useScandiumQuery(
    `/projects/${activeProject?.id}/settings`,
    {
      enabled: !!activeProject?.id,
      onSuccess: (data) => {
        setPreStepDelay(data.data?.pre_step_delay || '');
        setStepTimeout(data.data?.step_timeout || '');
        setFailureMode(data.data?.failure_mode || '');
        setUseNativeEvents(data.data?.use_native_event);
        setStartingUrl(data.data?.starting_url || '');
      }
    }
  );

  const handleSaveProjectSettings = async (event) => {
    event.preventDefault();

    await saveProjectDefaults({
      pre_step_delay: preStepDelay,
      step_timeout: stepTimeout,
      starting_url: startingUrl,
      use_native_event: nativeEvents,
      failure_mode: failureMode
    });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: '30rem' }}>
      <Box
        as={'form'}
        onSubmit={handleSaveProjectSettings}
        sx={{ width: { xs: '100%', sm: '50%' } }}>
        <BackButton label={'Back'} onClick={() => navigate('..')} />
        <Typography
          color="primary"
          sx={{ textAlign: 'left', fontSize: '1.1rem', mt: { xs: 4, sm: 4 }, mb: 3 }}>
          Project Default Settings
        </Typography>
        <TextField
          onChange={(event) => {
            setPreStepDelay(event.target.value);
          }}
          label="Pre Step Delay"
          value={preStepDelay}
          type={'number'}
          size={'small'}
          sx={{ mb: 1 }}
          fullWidth
          InputProps={{
            endAdornment: !isFetchingProjectSettings ? undefined : (
              <InputAdornment position="end">
                <CircularProgress size={12} color={'secondary'} />
              </InputAdornment>
            )
          }}
        />
        <TextField
          onChange={(event) => {
            setStepTimeout(event.target.value);
          }}
          label="Step Timeout"
          type={'number'}
          value={stepTimeout}
          size={'small'}
          sx={{ mb: 1 }}
          fullWidth
          InputProps={{
            endAdornment: !isFetchingProjectSettings ? undefined : (
              <InputAdornment position="end">
                <CircularProgress size={12} color={'secondary'} />
              </InputAdornment>
            )
          }}
        />
        <TextField
          onChange={(event) => {
            setStartingUrl(event.target.value);
          }}
          label="Starting URL..."
          value={startingUrl}
          type={'url'}
          placeholder={'https://www.example.com'}
          size={'small'}
          sx={{ mb: 1 }}
          fullWidth
          InputProps={{
            endAdornment: !isFetchingProjectSettings ? undefined : (
              <InputAdornment position="end">
                <CircularProgress size={12} color={'secondary'} />
              </InputAdornment>
            )
          }}
        />

        <Flex sx={{ flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}>
          <FormControl>
            <FormLabel
              id="Preconditions-controlled-radio-buttons-group"
              sx={{
                fontWeight: 'bold',
                color: theme.palette.text.custom
              }}>
              Failure Mode
            </FormLabel>
            <Typography textAlign={'left'} sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
              When a step fails
            </Typography>
            <RadioGroup
              aria-labelledby="Preconditions-controlled-radio-buttons-group"
              name="Preconditions-controlled-radio-buttons-group"
              value={failureMode}
              onChange={(event) => setFailureMode(event.target.value)}>
              <FormControlLabel
                value="fail"
                control={
                  <Radio
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Fail test immediately
                    <Tooltip title="When this step fails, the test is marked as failed, and subsequent steps are not executed">
                      <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                    </Tooltip>
                  </Typography>
                }
              />
              <FormControlLabel
                value="continue"
                control={
                  <Radio
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Fail and continue
                    <Tooltip title="When this step fails, the test is marked as failed, but subsequent steps are executed.">
                      <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                    </Tooltip>
                  </Typography>
                }
              />
              <FormControlLabel
                value="ignore"
                control={
                  <Radio
                    sx={{
                      py: 0.5,
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                    size="small"
                  />
                }
                label={
                  <Typography
                    fontSize={13}
                    sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    Ignore failure
                    <Tooltip title="The 'Ignore failure' mode will execute all test cases even when the extension report a failure">
                      <InfoOutlinedIcon sx={{ fontSize: '14px', color: '#444444' }} />
                    </Tooltip>
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          <FormControl sx={{ mt: 2 }}>
            <FormLabel
              id="Preconditions-controlled-radio-buttons-group"
              sx={{ fontWeight: 'bold', color: theme.palette.text.custom }}>
              Use Native Events
            </FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  sx={{
                    '&.Mui-checked': {
                      color: theme.palette.radio.main
                    }
                  }}
                  onChange={(e) => setUseNativeEvents(e.target.checked)}
                  checked={!!nativeEvents}
                  inputProps={{ 'arial-label': 'use native events' }}
                />
              }
              label="Use native event"
            />
          </FormControl>
        </Flex>

        <Divider sx={{ my: 1 }} />

        <ContainedButton
          disableRipple={true}
          type={'submit'}
          fullWidth
          size={'small'}
          isLoading={isLoading}
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            py: 1.5,
            mt: 1
          }}>
          Save
        </ContainedButton>
      </Box>
    </Box>
  );
}

export default DefaultSettings;
