import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NewFeatureBadge = () => {
  return (
    <Box
      sx={{
        py: 0.5,
        px: 1,
        backgroundColor: '#FF6347',
        borderRadius: '24px',
        width: 'max-content'
      }}>
      <Typography
        sx={{ color: '#FFF', textTransform: 'capitalize', lineHeight: 1 }}
        fontSize={'0.7rem'}>
        New
      </Typography>
    </Box>
  );
};

export default NewFeatureBadge;
