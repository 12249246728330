import React, { useEffect } from 'react';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { ColorContext } from '../../../AppTheme';

const PreconditionCustomExpressionModal = ({
  open,
  onClose,
  value,
  onComplete,
  onHandleUpdate,
  initialValue
}) => {
  const { mode } = React.useContext(ColorContext);
  const [code, setCode] = React.useState(initialValue || '');

  useEffect(() => {
    if (initialValue) {
      setCode(initialValue);
    }
  }, [initialValue]);

  const editorOptions = {
    fontSize: 12,
    formatOnPaste: true,
    renderIndentGuides: true,
    formatOnType: true,
    inlineSuggest: true,
    autoClosingBrackets: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  const handleCloseModal = () => {
    setCode('');
    onClose();
  };

  const handleSaveCustomExpression = () => {

    onHandleUpdate({
      conditions: [
        {
          type: 'custom-expression',
          text: code
        }
      ]
    });

    onComplete();
    setCode('');
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'550px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem',
            mb: 3
          }}>
          Custom Javascript Expression Precondition
        </Typography>

        <Editor
          height={'400px'}
          theme={mode === 'dark' ? 'vs-dark' : 'blackboard'}
          value={code}
          onChange={(value, event) => setCode(value)}
          language={'javascript'}
          options={editorOptions}
        />

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={handleCloseModal}>Close</OutlinedButton>
          <ContainedButton
            color={'primary'}
            onClick={handleSaveCustomExpression}
            autoFocus>
            Save
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default PreconditionCustomExpressionModal;
