import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Fab,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography
} from '@mui/material';
import BackButton from '../../../components/base/BackButton';
import { statusColors, statusMap } from '../../dashboard/components';
import Flex from '../../../components/base/Flex';
import ContainedButton from '../../../components/base/ContainedButton';
import HelpIcon from '@mui/icons-material/Help';
import { RiSettings4Fill } from 'react-icons/ri';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { getMoreReadableDuration } from '../../../utils/time';
import Zoom from 'react-medium-image-zoom';
import { rgba } from '../../../utils/colors';
import PassedSvg from '../../../components/svg/PassedSvg';
import SkippedSvg from '../../../components/svg/SkippedSvg';
import FailedSvg from '../../../components/svg/FailedSvg';
import ComingSoonBadge from '../../../components/base/ComingSoonBadge';
import { useActiveProject } from '../../../store/projectState';
import { processString } from './StatusBadge';
import TextButton from '../../../components/base/TextButton';
import { formatDate } from '../../../utils/formateDate';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../../mixpanel.constants';

const TabPanel = ({ children, value, index }) => {
  return <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>;
};

const StatusBadge = ({ status, error }) => {
  const _status = status in statusMap ? statusMap[status] : status;
  const iconMap = {
    passed: <PassedSvg />,
    failed: <FailedSvg />,
    skipped: <SkippedSvg />
  };

  return (
    <Flex
      alignItems={'center'}
      backgroundColor={rgba(statusColors[_status], 15)}
      px={1}
      py={1}
      color={statusColors[_status]}
      sx={{
        border: `1px solid ${statusColors[_status]}`,
        borderRadius: '4px'
      }}>
      {iconMap[_status] || null}
      <Typography textTransform={'capitalize'} fontWeight={500} lineHeight={1}>
        {_status === 'failed' && error ? `Failed: ${error}` : _status}
      </Typography>
    </Flex>
  );
};

const StepDetails = ({
  testTitle,
  event,
  runResult,
  onOpenSettings,
  onClose,
  onNext,
  onPrev,
  handleUpdateEventScreenshot,
  logData
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const activeProject = useActiveProject();
  const readyOnly = activeProject?.read_only;
  const theme = useTheme();

  const handleKeydown = useCallback(
    (event) => {
      if (event.key === 'ArrowRight' && onNext) return onNext();
      if (event.key === 'ArrowLeft' && onPrev) return onPrev();
    },
    [onNext, onPrev]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  const linkText = processString(runResult?.error);

  const getBackgroundColor = (level) => {
    switch (level) {
      case 'DEBUG':
        return '#FFECB3';
      case 'INFO':
        return '#EEE';
      case 'ERROR':
        return '#FFCDD2';
      case 'SEVERE':
        return '#FFCDD2';
      default:
        return '#FFFFFF';
    }
  };

  const getIcon = (level) => {
    switch (level) {
      case 'ERROR':
        return <ErrorOutlineIcon sx={{ color: '#000' }} />;
      case 'INFO':
        return <InfoOutlinedIcon sx={{ color: '#000' }} />;
      case 'DEBUG':
        return <BugReportOutlinedIcon sx={{ color: '#000' }} />;
      case 'SEVERE':
        return <BugReportOutlinedIcon sx={{ color: '#000' }} />;
      default:
        return <InfoOutlinedIcon sx={{ color: '#000' }} />;
    }
  };

  return (
    <Box flex={1} mr={2} mb={4}>
      <BackButton label={'Back'} onClick={onClose} />

      <Flex alignItems={'flex-end'} justifyContent={'space-between'}>
        <Box>
          {/*Breadcrumb*/}
          <Box>
            <Typography
              color="primary"
              sx={{
                textAlign: 'left',
                fontSize: '1.1rem'
              }}>
              {testTitle} / {event.title}
            </Typography>
          </Box>

          {!!runResult && (
            <Flex flexWrap={'wrap'} columnGap={2} flex={1} sx={{ mt: 2 }} alignItems={'center'}>
              <Flex columnGap={0}>
                <StatusBadge status={runResult.status} error={runResult.error}></StatusBadge>
                {runResult.status === 'error' && (
                  <TextButton
                    component={'a'}
                    onClick={() => {
                      trackMixPanel(TRACKING_IDS.GET_HELP_CLICKED, {
                        position: 'Step Details'
                      });
                    }}
                    sx={{
                      borderRadius: '4px',
                      color: theme.palette.text.black_white
                    }}
                    href={`https://docs.getscandium.com/why-did-my-test-fail#${linkText}`}
                    target={'_blank'}
                    startIcon={<HelpIcon fontSize="small" />}>
                    Get help
                  </TextButton>
                )}
              </Flex>

              <Flex alignItems={'flex-start'} flexDirection={'column'} mb={0.5} rowGap={0}>
                <Typography fontWeight={500}>Time taken:</Typography>
                <Typography
                  as={'span'}
                  textTransform={'capitalize'}
                  fontSize={'0.9rem'}
                  color={'gray'}>
                  {getMoreReadableDuration(runResult.time_taken) || '0ms'}
                </Typography>
              </Flex>
            </Flex>
          )}
        </Box>

        {!!onOpenSettings && (
          <ContainedButton
            startIcon={<RiSettings4Fill fontSize={'1rem'} />}
            disabled={!!readyOnly}
            onClick={onOpenSettings}>
            Settings
          </ContainedButton>
        )}
      </Flex>

      {runResult?.variableValue && (
        <Flex sx={{ my: 1 }}>
          <Typography>Variable value - </Typography>
          <Box
            sx={{
              py: 0.5,
              px: 1,
              backgroundColor: '#1958B8',
              borderRadius: '24px',
              width: 'max-content'
            }}>
            <Typography
              sx={{ color: '#FFF', textTransform: 'capitalize', lineHeight: 1 }}
              fontSize={'0.7rem'}>
              {runResult?.variableValue}
            </Typography>
          </Box>
        </Flex>
      )}

      <Box
        sx={{
          px: 3,
          py: 3,
          mt: 2,
          borderRadius: '16px',
          borderColor: theme.palette.table.mobileOutline,
          borderWidth: '2px',
          borderStyle: 'solid'
        }}>
        <Tabs
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          indicatorColor={'secondary'}
          sx={{
            borderBottom: `3px solid ${theme.palette.table.outline}`
          }}
          TabIndicatorProps={{
            sx: { height: 3 }
          }}>
          <Tab label={'Baseline'} sx={{ textTransform: 'none', textAlign: 'left' }} />
          {!!runResult && (
            <Tab label={'Results'} sx={{ textTransform: 'none', textAlign: 'left' }} />
          )}
          {!!runResult && (
            <Tab
              label={<Flex>Console Log</Flex>}
              sx={{ textTransform: 'none', textAlign: 'right' }}
            />
          )}
          {!!runResult && (
            <Tab
              disabled
              label={
                <Flex>
                  Network Log
                  <ComingSoonBadge />
                </Flex>
              }
              sx={{ textTransform: 'none', textAlign: 'right' }}
            />
          )}
        </Tabs>
        <TabPanel value={!!runResult ? selectedTab : 0} index={0}>
          <Flex justifyContent={'center'} sx={{ py: 4 }}>
            <Box sx={{ maxWidth: '50%' }}>
              <Zoom>
                <Box
                  as={'img'}
                  alt={
                    event.screenshot?.imageData
                      ? 'Page screenshot'
                      : 'No screenshots available for this step'
                  }
                  src={event.screenshot?.imageData || '/images/blank-img.png'}
                  sx={{ width: '100%' }}
                />
              </Zoom>
            </Box>
          </Flex>
        </TabPanel>

        {!!runResult && (
          <TabPanel value={selectedTab} index={1}>
            <Flex
              justifyContent={'space-around'}
              sx={{ py: 4, maxWidth: '100%' }}
              alignItems={'flex-start'}
              py={2}>
              <Box sx={{ flex: 1, maxWidth: '50%' }}>
                <Box
                  sx={{
                    borderTopRightRadius: '8px',
                    px: 2,
                    py: 0.5,
                    bgcolor: '#24C3D9',
                    color: '#FFF',
                    width: 'max-content'
                  }}>
                  Baseline
                </Box>
                <Zoom>
                  <Box
                    as={'img'}
                    src={event.screenshot?.imageData || '/images/blank-img.png'}
                    sx={{ width: '100%' }}
                  />
                </Zoom>
              </Box>
              <Box sx={{ flex: 1, maxWidth: '50%' }}>
                <Flex justifyContent={'space-between'}>
                  <Box
                    sx={{
                      borderTopRightRadius: '8px',
                      px: 2,
                      py: 0.5,
                      bgcolor: '#24C3D9',
                      color: '#FFF',
                      width: 'max-content'
                    }}>
                    Result
                  </Box>
                  {runResult?.result_screenshot?.imageData && (
                    <TextButton
                      onClick={() =>
                        handleUpdateEventScreenshot(
                          runResult.step_id,
                          runResult.result_screenshot.imageData
                        )
                      }>
                      Set as Baseline
                    </TextButton>
                  )}
                </Flex>
                <Zoom>
                  <Box
                    as={'img'}
                    src={runResult.result_screenshot?.imageData || '/images/blank-img.png'}
                    sx={{ width: '100%' }}
                  />
                </Zoom>
              </Box>
            </Flex>
          </TabPanel>
        )}

        {!!runResult && (
          <TabPanel value={selectedTab} index={2}>
            <Box sx={{ py: 2 }}>
              <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto' }}>
                <Table size="small" sx={{ borderCollapse: 'collapse' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Level</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Timestamp</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logData?.[0]?.value?.map((entry, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: getBackgroundColor(entry.level),
                          '&:hover': { backgroundColor: getBackgroundColor(entry.level) },
                          border: 'none',
                          borderBottom: '2px solid #FFF'
                        }}>
                        <TableCell
                          sx={{
                            border: 'none'
                          }}>
                          <Flex>
                            {getIcon(entry.level)}
                            <Typography variant="body2" color={'black'}>
                              {entry.level}
                            </Typography>
                          </Flex>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color={'black'}>
                            {entry.message}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color={'black'}>
                            {formatDate(entry.timestamp)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </TabPanel>
        )}
      </Box>

      <Flex justifyContent={'space-between'} flex={1} mt={2}>
        <Fab
          onClick={onPrev}
          disabled={!onPrev}
          size={'small'}
          color={'gray'}
          elevation={0}
          sx={{
            width: '28px',
            height: '28px',
            minHeight: '28px',
            boxShadow: 0,
            color: theme.palette.text.black_grey
          }}
          aria-label={'Next event'}>
          <ChevronLeft sx={{ color: theme.palette.svg.primary }} />
        </Fab>

        <Fab
          onClick={onNext}
          disabled={!onNext}
          size={'small'}
          color={'grey'}
          sx={{
            width: '28px',
            height: '28px',
            minHeight: '28px',
            boxShadow: 0,
            color: theme.palette.text.black_grey
          }}
          aria-label={'Previous event'}>
          <ChevronRight sx={{ color: theme.palette.svg.primary }} />
        </Fab>
      </Flex>
    </Box>
  );
};

export default StepDetails;
