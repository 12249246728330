import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography, Checkbox } from '@mui/material';
import React from 'react';
import { useMenu } from '../../../components/base/useMenu';
import { useConfirmDialog } from '../../../components/base/ConfirmDialog';
import CreateGroup from './CreateGroup';
import useAwaitModal from '../../../hooks/useAwaitModal';
import { useParams } from 'react-router-dom';
import AddToGroup from './AddToGroup';
import NewFeatureBadge from '../../../components/base/NewFeatureBadge';

export const BulkStepActionsTrigger = ({
  selectedSteps,
  onDeleteSteps,
  onGroupCreated,
  onGroupAssociated,
  testGroups,
  disableReason,
  onDuplicateSteps,
  selectAllSteps,
  areAllEventsSelected,
  onRequestBulkSettings
}) => {
  const { triggerProps, menuProps } = useMenu();
  const { testId } = useParams();

  // use the useconfirmdialog to show a confirmation dialog when the user clicks on the delete selected steps menu item
  const { requestConfirm, ConfirmationDialog } = useConfirmDialog();
  const { requestConfirm: confirmDuplicate, ConfirmationDialog: DuplicationDialog } =
    useConfirmDialog();
  const [
    requestGroupCreation,
    {
      open: openGroupCreation,
      onClose: onCloseGroupCreation,
      onComplete: completeGroupCreationModal
    }
  ] = useAwaitModal();

  const [
    requestAddToGroupModal,
    {
      open: addToGroupOpen,
      onClose: onCloseAddToGroup,
      onComplete: completeAddToGroupModal,
      ...otherProps
    }
  ] = useAwaitModal();

  // create dropdown menu similar to the one in src/pages/TestPage/components/ProtocolMenu.jsx with MoreVert as the trigger
  return (
    <Box>
      <Tooltip title="Select all steps">
        <IconButton size="small">
          <Checkbox onChange={selectAllSteps} checked={areAllEventsSelected} size="small" />
        </IconButton>
      </Tooltip>
      <IconButton {...triggerProps}>
        <MoreVert />
      </IconButton>
      <Menu id="action-menu" {...menuProps} elevation={1}>
        <MenuItem
          onClick={() => {
            onRequestBulkSettings();
            menuProps.onClose();
          }}>
          <Typography mr={2}>Edit</Typography> <NewFeatureBadge />
        </MenuItem>

        {/*Disable if test is not saved*/}
        <Tooltip title={disableReason['create_group']}>
          <div>
            <MenuItem disabled={!!disableReason['create_group']} onClick={requestGroupCreation}>
              <Typography>Add to new group</Typography>
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip title={disableReason['add_to_group']}>
          <div>
            <MenuItem disabled={disableReason['add_to_group']} onClick={requestAddToGroupModal}>
              <Typography>Add to existing group</Typography>
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip title={disableReason['insert_group_before']}>
          <div>
            <MenuItem
              disabled={disableReason['insert_group_before']}
              onClick={() => requestAddToGroupModal({ addSteps: false })}>
              <Typography>Insert group Before</Typography>
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip title={disableReason['insert_group_after']}>
          <div>
            <MenuItem
              disabled={disableReason['insert_group_after']}
              onClick={() => requestAddToGroupModal({ addSteps: false, insertAfter: true })}>
              <Typography>Insert group After</Typography>
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip title={'Delete the selected steps'}>
          <div>
            <MenuItem
              // disabled={disableReason['delete']}
              onClick={async () => {
                const confirm = await requestConfirm();
                if (confirm) {
                  onDeleteSteps();
                }
              }}>
              <Typography>Delete selected steps</Typography>
            </MenuItem>
          </div>
        </Tooltip>
        <Tooltip title={disableReason['duplicate']}>
          <div>
            <MenuItem
              disabled={disableReason['duplicate']}
              onClick={async () => {
                const confirm = await confirmDuplicate();
                if (confirm) {
                  onDuplicateSteps();
                }
              }}>
              <Typography>Duplicate selected steps</Typography>
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
      <ConfirmationDialog
        title={'Are you sure you want to delete the selected steps?'}
        description={'This action cannot be reversed and you may have to record them again.'}
        confirmLabel={'Delete'}
        cancelLabel={"Don't delete"}
        confirmColor={'error'}
      />
      <DuplicationDialog
        title={'Are you sure you want to duplicate the selected steps?'}
        description={
          'This action will create an exact copy of the steps selected. Any changes made to the duplicated steps will not affect the original ones.'
        }
        confirmLabel={'Duplicate'}
        cancelLabel={"Don't duplicate"}
        confirmColor={'primary'}
      />
      <CreateGroup
        open={openGroupCreation}
        onClose={onCloseGroupCreation}
        selectedSteps={selectedSteps}
        onGroupCreated={(group) => {
          // close the modal and resolve the promise that allows the `await` hook in `requestGroupCreation` to know we're done
          completeGroupCreationModal();
          // inform the parent they can do whatever they want with the newly created group
          onGroupCreated(group);
        }}
      />
      <AddToGroup
        open={addToGroupOpen}
        testGroups={testGroups}
        onStepsAdded={(group, didAddSteps, insertAfter) => {
          completeAddToGroupModal();
          onGroupAssociated(group, didAddSteps, insertAfter);
        }}
        onClose={onCloseAddToGroup}
        selectedSteps={selectedSteps}
        {...otherProps}
      />
    </Box>
  );
};
