import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ContainedButton from '../../components/base/ContainedButton';
import { useScandiumMutation } from '../../data-layer/utils';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Logo from '../../components/base/Logo';
import { identifyMixpanelUser, setMixpanelUser, TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
import useUpdateToken from '../../hooks/useUpdateToken';

function VerifyOtp() {
  useDocumentTitle('Scandium - Verify Your Email Address');
  let navigate = useNavigate();
  const [textFieldValues, setTextFieldValues] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef()
  ]);
  const updateToken = useUpdateToken();

  const token = textFieldValues.join('');

  const { mutateAsync, isLoading: showProgress } = useScandiumMutation('/auth/email/verify', {
    onSuccess: (data) => {
      trackMixPanel(TRACKING_IDS.SIGNUP_SUCCESSFUL, {
        status: true
      });

      updateToken(data);
      navigate('/dashboard');

      identifyMixpanelUser(data.user?.id);
      setMixpanelUser(data)

      trackMixPanel(TRACKING_IDS.LOGIN_STATUS, {
        status: true
      });

    },
    onError: () => {
      trackMixPanel(TRACKING_IDS.SIGNUP_SUCCESSFUL, {
        status: false
      });
    }
  });

  const { mutateAsync: resendOtp } = useScandiumMutation('/auth/email/resend', {
    method: 'GET'
  });

  const handleChange = (e, index) => {
    const newValue = e.target.value;

    setTextFieldValues(textFieldValues.map((value, i) => (i === index ? newValue : value)));
    if (newValue !== '' && index !== 5) {
      inputRefs.current[index + 1].current.focus();
    } else if (newValue === '' && index !== 0) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const content = (
      (e.clipboardData || window.clipboardData).getData('text')?.toUpperCase() || ''
    ).split('');
    setTextFieldValues(textFieldValues.map((value, index) => content[index] || value));
    const nextIndex =
      content.length >= textFieldValues.length ? textFieldValues.length - 1 : content.length;
    inputRefs.current[nextIndex].current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await mutateAsync({ token });
      toast.success(
        'Your email is verified and your account was successfully created. We have also sent a confirmation mail to the email address you.'
      );
    } catch (error) {
      toast.error(error.message || 'An error occurred, please try again');
    }
  };

  const handleResend = async () => {
    try {
      await resendOtp();
      toast.success('Verification email sent');
    } catch (error) {
      toast.error(error.message || 'An error occurred, please try again');
    }
  };

  return (
    <Box
      sx={{
        mx: { xs: 3.5, sm: 5 }
      }}>
      <Box
        sx={{
          mt: { xs: 1, sm: 1 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Logo />
        <Typography
          sx={{
            cursor: 'pointer',
            textAlign: 'right',
            typography: { sm: 'h6', md: 'h6' }
            // typography: { sm: 'h6', md: 'h5' },
            // pt: { xs: 6, sm: 3, }
          }}>
          Password reset
        </Typography>
      </Box>
      <Box
        sx={{
          mx: { xs: 0, sm: 6 },
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Box
          sx={
            {
              // backgroundColor: 'pink'
            }
          }>
          <Typography
            sx={{
              textAlign: 'left',
              typography: { sm: 'h6', md: 'h4' },
              mt: { xs: 20, sm: 20 }
            }}>
            Verify your email address.
          </Typography>
          <Box
            sx={{
              width: '100%'
              // mt: { xs: 1, sm: 1, },
              // backgroundColor: 'blue'
            }}>
            <Typography
              component="span"
              sx={{
                typography: { sm: 'caption' },
                color: '#A3A3A3'
              }}>
              An email with a{' '}
            </Typography>
            <Typography
              component="span"
              sx={{
                // textAlign: 'left',
                typography: { sm: 'caption' }
                // pt: { xs: 6, sm: 3, },
              }}>
              one time verification code{' '}
            </Typography>
            <Typography
              component="span"
              sx={{
                textAlign: 'left',
                typography: { sm: 'caption' },
                color: '#A3A3A3'
              }}>
              has been sent to your email address
            </Typography>
          </Box>
          <Box
            sx={{
              mt: { xs: 6, sm: 4 },
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            {inputRefs.current.map((ref, index) => (
              <TextField
                required
                inputRef={ref}
                onChange={(e) => handleChange(e, index)}
                value={textFieldValues[index]}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
                sx={{
                  width: { xs: 48, sm: 48, md: 52, lg: 52 },
                  height: { xs: 48, sm: 48, md: 52, lg: 52 }
                }}
                inputProps={{
                  style: { textAlign: 'center' },
                  maxLength: 1
                }}
              />
            ))}
          </Box>
          <ContainedButton
            onClick={handleSubmit}
            disableRipple={true}
            fullWidth
            isLoading={showProgress}
            sx={{
              textAlign: 'center',
              textTransform: 'capitalize',
              mt: { xs: 2, sm: 3 },
              py: 1.5
            }}>
            Verify
          </ContainedButton>

          <Box
            sx={{
              mt: { xs: 3, sm: 3 },
              px: { xs: 1, sm: 1 }
            }}>
            <Typography
              component="span"
              sx={{
                textAlign: 'right',
                typography: { sm: 'body2' },
                color: '#A3A3A3'
              }}>
              Didn't receive an email ?{' '}
            </Typography>
            <Typography
              onClick={handleResend}
              component="span"
              sx={{
                cursor: 'pointer',
                textAlign: 'right',
                typography: { sm: 'body2' },
                textDecoration: 'underline'
              }}>
              Resend
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default VerifyOtp;
