import { SvgIcon } from '@mui/material';

const EventRecordSvg = ({ width = 25, height = 25, color = '#1958B8', fill, ...props }) => {
  return (
    <SvgIcon
      sx={{ width, height }}
      viewBox={'0 0 10 9'}
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.11621 4.31934C9.11621 6.70484 7.18238 8.63867 4.79688 8.63867C2.41137 8.63867 0.477539 6.70484 0.477539 4.31934C0.477539 1.93383 2.41137 0 4.79688 0C7.18238 0 9.11621 1.93383 9.11621 4.31934Z"
        fill={fill || color}
      />
      <path
        d="M4.79696 6.5236C6.01406 6.5236 7.00071 5.53695 7.00071 4.31985C7.00071 3.10276 6.01406 2.11611 4.79696 2.11611C3.57987 2.11611 2.59322 3.10276 2.59322 4.31985C2.59322 5.53695 3.57987 6.5236 4.79696 6.5236Z"
        fill="#EEEEEE"
      />
      <path
        d="M4.79696 5.10691C5.23164 5.10691 5.58401 4.75453 5.58401 4.31985C5.58401 3.88518 5.23164 3.5328 4.79696 3.5328C4.36229 3.5328 4.00991 3.88518 4.00991 4.31985C4.00991 4.75453 4.36229 5.10691 4.79696 5.10691Z"
        fill="#8BB3F1"
      />
    </SvgIcon>
  );
};

export default EventRecordSvg;
