/* global chrome */
import {
  Badge,
  Box,
  Tab,
  Tabs,
  Typography,
  Divider,
  FormControl,
  Pagination,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import { DateTime } from 'luxon';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useRef, useState } from 'react';
import Flex from '../../components/base/Flex';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useScandiumQuery } from '../../data-layer/utils';
import { useActiveProject } from '../../store/projectState';
import ResultsTable from './components/ResultsTable';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import EmptyState from '../../components/base/EmptyState';
import OutlinedButton from '../../components/base/OutlinedButton';
import { useDebounce } from 'use-debounce';
import ErrorBoundary from '../../components/base/ErrorBoundary';
import { statusColors, statusMap } from '../dashboard/components';
import { rgba } from '../../utils/colors';
import PassedSvg from '../../components/svg/PassedSvg';
import SkippedSvg from '../../components/svg/SkippedSvg';
import FailedSvg from '../../components/svg/FailedSvg';
import { getMoreReadableDuration } from '../../utils/time';
import ErrorState from '../../components/base/ErrorState';
import { format } from 'date-fns';
import jsFileDownload from 'js-file-download';
import Reports from '../TestRuns/components/Reports';
import { getCurrentDate } from './utils';
import usePagination from '../../hooks/usePagination';
import AbortSvg from '../../components/svg/AbortSvg';
import { getExtensionId } from '../TestPage';
import InstallExtensionCta from '../TestPage/components/InstallExtensionCta';
import { Search, SearchIconWrapper, StyledInputBase } from '../../components/helpers/inputHelper';
import { Ranges } from '../Reports/Reports';
import { tabValues } from '../TestRuns/TestRuns';
import { useTheme } from '@emotion/react';
import { TRACKING_IDS, trackMixPanel } from '../../mixpanel.constants';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { StatusDot } from '../dashboard/DashboardCards';
import SvgChart from '../dashboard/TestStatusChart';

const StatusBadge = ({ status }) => {
  const _status = status in statusMap ? statusMap[status] : status;
  const iconMap = {
    passed: <PassedSvg />,
    failed: <FailedSvg />,
    skipped: <SkippedSvg />
  };

  return (
    <Flex
      alignItems={'center'}
      backgroundColor={rgba(statusColors[_status], 15)}
      px={1}
      py={1}
      color={statusColors[_status]}
      sx={{
        border: `1px solid ${statusColors[_status]}`,
        borderRadius: '4px'
      }}>
      {iconMap[_status] || null}
      <Typography textTransform={'capitalize'} fontWeight={500} lineHeight={1}>
        {_status}
      </Typography>
    </Flex>
  );
};

const SuitesRuns = () => {
  useDocumentTitle('Suites Runs');
  const [activeSuiteCollectionName, setActiveSuiteCollectionName] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  const [dateRange, setDateRange] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeTab, setActiveTab] = useState('all');
  const activeProject = useActiveProject();
  const { suiteId } = useParams();
  const navigate = useNavigate();
  const [report_format, setReportFormat] = useState('excel');
  const [mode, setMode] = useState('simple');
  const [source, setSource] = useState('all');
  const [openModal, setOpenModal] = useState(false);
  const extRef = useRef(getExtensionId());
  const theme = useTheme();

  const readyOnly = activeProject?.read_only;

  const { afterToday } = DateRangePicker;

  const { activePage, pageLimit, DEFAULT_PAGE_SIZE, handlePageChange, handleSelectPageChange } =
    usePagination();

  const onNavigate = (row) => {
    if (!suiteId) return navigate(`/projects/${activeProject?.id}/suites-results/${row.id}`);
    return navigate(
      `/projects/${activeProject?.id}/folders/tests/${row.test_case.id}/runs/${row?.id}?limit=50`
    );
  };

  useEffect(() => {
    const dateFrom = searchParams.get('date_from');
    const dateTo = searchParams.get('date_to');

    if (dateFrom && dateTo) {
      setDateRange([new Date(dateFrom), new Date(dateTo)]);
    }
  }, []);

  useEffect(() => {
    const currentTab = searchParams.get('status');
    if (tabValues[currentTab]) {
      setActiveTab(currentTab);
    } else {
      setActiveTab('all');
    }
  }, [searchParams]);

  const handleChangeDatePicker = (newValue) => {
    setDateRange(newValue);

    // Update URL parameters
    if (newValue?.length === 2) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        date_from: newValue[0].toISOString().split('T')[0],
        date_to: newValue[1].toISOString().split('T')[0]
      });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('date_from');
      newParams.delete('date_to');
      setSearchParams(newParams);
      setDateRange(null);
    }
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    const newParams = new URLSearchParams(searchParams);
    if (tabValues[newTab].queryParam) {
      newParams.set('status', tabValues[newTab].queryParam);
    } else {
      newParams.delete('status');
    }
    setSearchParams(newParams);
  };

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    const params = new URLSearchParams(searchParams);

    if (!newQuery) {
      params.delete('query');
    } else {
      params.set('query', newQuery);
    }

    setSearchParams(params, { replace: true });
  };

  const fileExtensionMap = {
    excel: 'xlsx',
    csv: 'csv',
    pdf: 'pdf'
  };

  const { data, error, isLoading, isSuccess } = useScandiumQuery(
    `/projects/${activeProject?.id}/${suiteId ? `execution-runs/${suiteId}` : `execution-runs`}`,
    {
      enabled: !!activeProject?.id,
      refetchInterval: 10000,
      params: {
        status: !suiteId ? tabValues[activeTab].queryParam : undefined,
        search: !suiteId ? debouncedQuery || undefined : undefined,
        date_from: dateRange?.[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined,
        date_to: dateRange?.[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined,
        page: activePage,
        limit: pageLimit
      }
    }
  );
  useEffect(() => {
    const activeSuiteCollection = () => {
      if (!!isSuccess && !!suiteId) {
        setActiveSuiteCollectionName(`/ ${data.data.name}`);
      }
    };
    activeSuiteCollection();
  }, [isSuccess, suiteId]);

  const suiteRuns = !suiteId
    ? data?.runs?.data
    : data?.data?.results
        ?.flatMap((test) => test.filter((run) => !status || run.status === status))
        .filter((run) => run.name?.toLowerCase().includes(debouncedQuery));

  const userTimezone = DateTime.local().zoneName;

  const { isLoading: isFetchingReport, refetch: downloadReport } = useScandiumQuery(
    `/projects/${activeProject?.id}/reports/suite-runs`,
    {
      json: false,
      enabled: false,
      headers: {
        'SCANDIUM-USER-TIMEZONE': userTimezone
      },
      params: {
        mode: mode,
        status: tabValues[activeTab].queryParam,
        format: report_format,
        search: debouncedQuery || undefined,
        date_from: dateRange?.[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined,
        date_to: dateRange?.[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined
      },
      onSuccess: (data) => {
        jsFileDownload(
          data,
          `Scandium_suite_runs_report${getCurrentDate()}.${fileExtensionMap[report_format]}`
        );
        toast.success('Your report is being downloaded');

        trackMixPanel(TRACKING_IDS.DATA_EXPORTED, {
          type: 'results'
        });
      },
      onError: (data) => {
        toast.error(data.message);
      }
    }
  );

  const abortSuiteRuns = () => {
    if (!window.chrome) {
      toast.error(
        'The Scandium extension only works on Chrome for now. Other browsers are coming soon'
      );
      return;
    }

    // In case you somehow got here without having the extension.
    extRef.current = getExtensionId();
    if (!extRef.current) {
      setOpenModal(true);
      toast.error(
        'The Scandium extension is yet to be installed on your browser. Please install the extension and try again'
      );
      return;
    }

    chrome.runtime.sendMessage(
      extRef.current,
      {
        cmd: 'stop-playback'
      },
      (res) => {
        toast.success('Suite runs stopped 🛑');
      }
    );
  };

  return (
    <Box sx={{ mt: -2 }}>
      <Box>
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ mb: 2 }}
          flexWrap={'wrap'}>
          <Typography fontWeight={'600'} as={'h2'} fontSize={'1.2rem'} color={'primary'}>
            {data?.name || data?.suite?.name || 'Suite Runs'}
          </Typography>
          {!!suiteId && (
            <Typography fontWeight={'400'} as={'h6'} color={'primary'}>
              {activeSuiteCollectionName}
            </Typography>
          )}

          <Flex ml={'auto'}>
            {!!suiteId && data?.data?.status === 'running' && (
              <OutlinedButton startIcon={<AbortSvg />} onClick={abortSuiteRuns}>
                Abort run
              </OutlinedButton>
            )}
            {!suiteId && (
              <>
                <DateRangePicker
                  shouldDisableDate={afterToday()}
                  appearance={'default'}
                  style={{ width: 220 }}
                  value={dateRange}
                  onChange={handleChangeDatePicker}
                  placement={'bottom'}
                  ranges={Ranges}
                  size={'sm'}
                  placeholder={'Filter by Date Range'}
                />
              </>
            )}
            <Search sx={{ width: { xs: 'max-content', ml: -1 } }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Search>
          </Flex>
        </Flex>
      </Box>

      {!!suiteId && !!data?.data?.results && (
        <Flex sx={{ justifyContent: 'flex-start'}} flexWrap={'wrap'}>
          <Flex flexWrap={'wrap'} columnGap={2} sx={{ my: 3 }} alignItems={'center'}>
            <StatusBadge status={data?.data?.status} />
            <Typography fontWeight={500}>
              {`${
                data?.data?.status === 'success'
                  ? data?.data?.summary?.passed
                  : data?.data?.summary?.failed
              } of ${data?.data?.summary?.total}`}
            </Typography>
            <Divider orientation={'vertical'} flexItem />
            <Flex alignItems={'flex-start'} flexDirection={'column'} mb={0.5} rowGap={0}>
              <Typography fontWeight={500}>Time taken:</Typography>
              <Typography
                as={'span'}
                textTransform={'capitalize'}
                fontSize={'0.9rem'}
                color={'gray'}>
                {getMoreReadableDuration(data?.data?.duration) || '0ms'}
              </Typography>
            </Flex>
          </Flex>

          <Box
            width={'80px'}
            height={'80px'}
            sx={{ position: 'relative' }}
            display={'flex'}
            flexDirection={'column'}
            gap={0.5}
            justifyContent={'center'}
            alignItems={'center'}>
            <Box position={'absolute'} top={0} left={0}>
              <SvgChart
                passing={(data?.data?.summary?.passed / (data?.data?.summary?.total || 1)) * 100}
                height={80}
              />
            </Box>
            <Flex columnGap={0.5}>
              <StatusDot status={'success'} />
              <Typography sx={{ color: '#00CA08' }} fontSize={'0.8rem'}>
                {Math.round(
                  (data?.data?.summary?.passed / (data?.data?.summary?.total || 1)) * 100
                )}
                %
              </Typography>
            </Flex>
            <Flex columnGap={0.5}>
              <StatusDot status={'error'} />
              <Typography sx={{ color: '#EF1B1B' }} fontSize={'0.7rem'}>
                {Math.round(
                  (data?.data?.summary?.failed / (data?.data?.summary?.total || 1)) * 100
                )}
                %
              </Typography>
            </Flex>
          </Box>
        </Flex>
      )}

      <Box>
        <Flex
          sx={{ borderBottom: `3px solid ${theme.palette.table.outline}` }}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label={'suites tabs'}
            indicatorColor={'primary'}
            TabIndicatorProps={{
              sx: { height: 3 }
            }}>
            <Tab
              label={
                <Flex columnGap={2}>
                  All
                  {(!!data?.data?.summary || !!data?.count) && (
                    <Badge
                      color={'primary'}
                      max={999}
                      badgeContent={data?.data?.summary?.total || data?.count?.all}></Badge>
                  )}
                </Flex>
              }
              value={'all'}
              sx={{ textTransform: 'none', textAlign: 'left' }}
            />
            <Tab
              label={
                <Flex columnGap={2}>
                  Passed
                  {(!!data?.data?.summary || !!data?.count) && (
                    <Badge
                      color={'primary'}
                      max={999}
                      badgeContent={data?.data?.summary?.passed || data?.count?.passed}></Badge>
                  )}
                </Flex>
              }
              value={'success'}
              sx={{ textTransform: 'none', textAlign: 'left' }}
            />
            <Tab
              label={
                <Flex columnGap={2}>
                  Failed
                  {(!!data?.data?.summary || !!data?.count) && (
                    <Badge
                      color={'primary'}
                      max={999}
                      badgeContent={data?.data?.summary?.failed || data?.count?.failed}></Badge>
                  )}
                </Flex>
              }
              value={'error'}
              sx={{ textTransform: 'none', textAlign: 'right' }}
            />
          </Tabs>
          {!suiteId && (
            <Reports
              handleDownloadReport={() => downloadReport()}
              isLoading={isFetchingReport}
              format={report_format}
              setReportFormat={setReportFormat}
              mode={mode}
              setMode={setMode}
              suite
              setSource={setSource}
              source={source}
              runs={suiteRuns}
            />
          )}
        </Flex>
        <ErrorBoundary key={selectedTab}>
          <ResultsTable suiteRuns={suiteRuns} onNavigate={onNavigate} suiteId={suiteId} />
        </ErrorBoundary>

        {isLoading && <PageLoader height={'100px'} />}
        {!isLoading &&
          (error ? (
            <ErrorState error={error} />
          ) : (
            suiteRuns?.length === 0 && (
              <EmptyState
                description={`Why not run a test suite a few times?`}
                cta={
                  <OutlinedButton
                    disabled={!!readyOnly}
                    component={NavLink}
                    to={`/projects/${activeProject?.id}/test-suites`}>
                    Run suites
                  </OutlinedButton>
                }></EmptyState>
            )
          ))}
      </Box>

      {!suiteId && data?.runs?.meta.last_page > 1 && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            mb: { xs: 6, sm: 8 }
          }}>
          <Pagination
            count={data?.runs?.meta.last_page}
            page={activePage}
            color="secondary"
            onChange={handlePageChange}
            size={'small'}
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#24C3D9',
                color: '#ffffff'
              },
              '& .MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#1958B8',
                color: '#fff'
              }
            }}
          />

          <FormControl sx={{ mr: 5 }}>
            <InputLabel id="go-to-page-label">Runs per page</InputLabel>
            <Select
              labelId={'go-to-page-label'}
              value={pageLimit}
              label={'Runs per page'}
              size={'small'}
              MenuProps={{
                elevation: 1
              }}
              onChange={handleSelectPageChange}>
              {[...Array(data?.runs?.meta.last_page).keys()].map((page, i) => (
                <MenuItem value={(page + 1) * DEFAULT_PAGE_SIZE} key={i}>
                  {(page + 1) * DEFAULT_PAGE_SIZE}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <InstallExtensionCta openModal={openModal} setOpenModal={setOpenModal} />
    </Box>
  );
};

export default SuitesRuns;
