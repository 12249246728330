import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import Tooltip from '@mui/material/Tooltip';
import { useActiveProject } from '../../../store/projectState';
import { useTheme } from '@emotion/react';
import { useScandiumMutation } from '../../../data-layer/utils';
import { toast } from 'react-toastify';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CreateTestCaseTags = ({
  open,
  onClose,
  onComplete,
  refetchTests,
  tags,
  refetchTags,
  testTags,
  testId
}) => {
  const activeProject = useActiveProject();
  const theme = useTheme();
  const [tagName, setTagName] = useState(testTags || []);

  useEffect(() => {
    setTagName(testTags || []);
  }, [testTags]);

  const { mutateAsync: createTestCaseTag, isLoading: isCreatingTags } = useScandiumMutation(
    `/projects/${activeProject?.id}/test-cases/tags`,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        refetchTests && refetchTests();
        refetchTags();
        onComplete();
      },
      onError: (error) => {
        toast.error(error.message);
      }
    }
  );

  const handleTagsCreation = async (e) => {
    e.preventDefault();

    await createTestCaseTag({
      test_case_id: testId,
      tags: tagName
    });
  };

  return (
    <CustomModal open={open}>
      <Box
        component={'form'}
        onSubmit={handleTagsCreation}
        py={1}
        px={2}
        width={'80vw'}
        maxWidth={'500px'}
        position={'relative'}>
        <Typography
          as={'h4'}
          color={theme.palette.text.custom}
          fontWeight={600}
          sx={{
            fontSize: '1.4rem',
            mb: 1
          }}>
          Test Case Tags
        </Typography>

        <Typography variant="body" color={theme.palette.text.black_grey}>
          Create a new tag for this test case or select an existing tag from the available options.
          When adding a new custom tag, press the "Enter Key" after typing in the value before
          saving.
        </Typography>

        <Autocomplete
          multiple
          id={'autocomplete-test-case-tags'}
          limitTags={3}
          freeSolo
          sx={{ mt: 2 }}
          value={tagName}
          onChange={(event, newValue) => {
            setTagName(newValue);
          }}
          options={tags.map((tag) => tag.name.en) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              size={'small'}
              placeholder={'Click to select a tag or type a new one...'}
              fullWidth
              sx={{
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.text.primary
                }
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <Box {...props} sx={{ py: '0.2px' }}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.radio.main
                  }
                }}
                style={{ marginRight: 6 }}
                checked={selected}
              />
              {option}
            </Box>
          )}
        />

        <Flex sx={{ mt: 3 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton
            onClick={() => {
              onClose();
              setTagName([]);
            }}>
            Cancel
          </OutlinedButton>
          <Tooltip title={!tagName ? 'Attach a tag before saving' : ''}>
            <ContainedButton
              isLoading={isCreatingTags}
              type={'submit'}
              color={'primary'}
              sx={{
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'all !important'
                }
              }}
              autoFocus>
              Save
            </ContainedButton>
          </Tooltip>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CreateTestCaseTags;
