import {
  Box,
  Checkbox,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import { rgba } from '../../../utils/colors';
import React, { useCallback, useEffect } from 'react';
import { Add, MoreVert, Remove } from '@mui/icons-material';
import LoupeIcon from '@mui/icons-material/Loupe';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CloseIcon from '@mui/icons-material/Close';
import ViewStepSvg from '../../../components/svg/ViewStepSvg';
import EventSettingsSvg from '../../../components/svg/EventSettingsSvg';
import EventRecordSvg from '../../../components/svg/EventRecordSvg';
import EventPlaySvg from '../../../components/svg/EventPlaySvg';
import EventPauseSvg from '../../../components/svg/EventPauseSvg';
import { useMenu } from '../../../components/base/useMenu';
import GroupSvg from '../../../components/svg/GroupSvg';
import { pluralize } from '../../../utils/strings';
import Flex from '../../../components/base/Flex';
import { Z_INDEXES } from '../../../utils/zIndexes';
import DraggableCard from './DraggableCard';
import useDebounce from '../../../utils/debounce';
import _ from 'lodash';
import { useActiveProject } from '../../../store/projectState';
import { useTheme } from '@emotion/react';
import useFeatureEnabled from '../../../hooks/useFeatureEnabled';
import { toast } from 'react-toastify';
import PremiumBadge from '../../../components/base/PremiumBadge';

const GridItem = styled(Box)(() => ({
  '&:last-of-type': {
    // marginRight: "auto",
  }
}));

export const eventCardSx = {
  px: 1,
  py: 2,
  flexBasis: '25%',
  width: '25%',
  minWidth: '200px',
  maxWidth: { xs: '250px', sm: '17rem' }
};

const Badge = ({ status }) => {
  const colors = {
    success: '#00CA08',
    error: '#EF1B1B',
    skipped: '#D4D2D2',
    ignored: '#474747'
  };

  const texts = {
    success: 'Passed',
    error: 'Failed',
    skipped: 'Skipped',
    ignored: 'Ignored'
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        py: 0.2,
        width: { xs: '50px', sm: '64px' },
        backgroundColor: colors[status],
        color: '#fff',
        textTransform: 'capitalize',
        textAlign: 'center',
        fontSize: '0.7rem',
        borderTopLeftRadius: 5,
        zIndex: 1,
        borderBottomRightRadius: 5
      }}>
      {texts[status]}
    </Box>
  );
};

const PreconditionIndicator = () => {
  return (
    <Box
      position={'absolute'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-around'}
      top={'-6px'}
      left={'-6px'}
      sx={{
        width: '12px'
      }}>
      <Tooltip title="View step details">
        <Box
          bgcolor={'#A4A4A4'}
          width={'12px'}
          height={'12px'}
          sx={{ transform: 'rotate(45deg)' }}
        />
      </Tooltip>
    </Box>
  );
};

const ActionIcons = ({
  disabled,
  onExpandSettings,
  onStartPlayback,
  onStartRecording,
  onInsertBreakpoint,
  onViewDetails,
  isExpanded,
  onExpand,
  onAddJavascriptStep,
  ...boxProps
}) => {
  const activeActions = [
    !!onExpandSettings,
    !!onStartPlayback,
    !!onStartRecording,
    !!onViewDetails
  ].filter((i) => i);
  const theme = useTheme();

  const activeProject = useActiveProject();
  const readyOnly = activeProject?.read_only;

  return (
    <Box
      position={'absolute'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-around'}
      top={isExpanded ? '-18px' : '-14px'}
      right={'12px'}
      sx={{
        backgroundColor: theme.palette.background.lightGrey_dark,
        boxShadow: 3,
        borderRadius: '28px',
        transition: 'width 0.1s ease-in-out',
        padding: isExpanded ? '4px' : '0px',
        width: isExpanded ? `calc(${28 * activeActions + 2}px + 8px)` : '28px'
      }}
      {...boxProps}>
      {isExpanded ? (
        <>
          <Tooltip title="View step details">
            <IconButton
              onClick={onViewDetails}
              size={'small'}
              color={'secondary'}
              aria-label={'View step details'}>
              <ViewStepSvg />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Start playback from this step'}>
            <IconButton
              onClick={onStartPlayback}
              disabled={!!readyOnly}
              size={'small'}
              color={'primary'}
              aria-label={'Start playback from this step'}>
              <EventPlaySvg />
            </IconButton>
          </Tooltip>
          {/* <Tooltip title={"Insert Breakpoint from this step"}>
            <IconButton
              onClick={onInsertBreakpoint}
              size={'small'}
              color={'primary'}
              aria-label={"Insert Breakpoint from this step"}>
              <EventPauseSvg />
            </IconButton>
          </Tooltip> */}
          {/* {onStartRecording && (
            <Tooltip title="Start recording from this step">
              <IconButton
                onClick={onStartRecording}
                disabled={!!readyOnly}
                size={'small'}
                color={'primary'}
                aria-label={'Start recording from this step'}>
                <EventRecordSvg />
              </IconButton>
            </Tooltip>
          )} */}
          {onStartRecording && (
            <ActionMenu
              onStartRecording={onStartRecording}
              onAddJavascriptStep={onAddJavascriptStep}
            />
          )}
          <Tooltip title="Open step settings">
            <IconButton
              disabled={!onExpandSettings || !!readyOnly}
              onClick={onExpandSettings}
              size={'small'}
              color={'secondary'}
              aria-label="Open test settings">
              <EventSettingsSvg />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
      <Tooltip title={isExpanded ? 'Hide options' : 'Expand options'}>
        <Fab
          onClick={() => onExpand(!isExpanded)}
          size={'small'}
          disabled={disabled}
          color={'primary'}
          sx={{ width: '28px', height: '28px', minHeight: '28px', zIndex: 200 }}
          aria-label={isExpanded ? 'Hide options' : 'Expand options'}>
          {isExpanded ? (
            <Remove fontSize={'1rem'} sx={{ color: '#fff' }} />
          ) : (
            <Add fontSize={'1rem'} sx={{ color: '#fff' }} />
          )}
        </Fab>
      </Tooltip>
    </Box>
  );
};

const getUrl = (screenshot) => {
  if (!screenshot || !screenshot.imageData) return '/images/blank-img.png';

  if (!screenshot.imageDimensions || !screenshot.screenDimensions) return screenshot.imageData;

  const xRatio = screenshot.imageDimensions.width / screenshot.screenDimensions.width;
  const yRatio = screenshot.imageDimensions.height / screenshot.screenDimensions.height;

  const w = Math.round(screenshot.width * xRatio);
  const h = Math.round(screenshot.height * yRatio);

  const l = Math.round(screenshot.offsetLeft * xRatio);
  const t = Math.round(screenshot.offsetTop * xRatio);

  const width = Math.max(w + 200 || 0, 500);
  const height = Math.max(h + 200 || 0, 500);

  // l=10,w=100,width=500, we want (500 - 100) / 2 is the padding. and l - padding is left
  const px = Math.round((width - w) / 2);
  const py = Math.round((height - h) / 2);

  const left = Math.max(l - px || 0, 0);
  const top = Math.max(t - py || 0, 0);

  return screenshot.imageData.replace(
    '/image/upload/',
    `/image/upload/c_crop,w_${width},h_${height},x_${left},y_${top}/`
  );
};

const ActionMenu = React.forwardRef(({ onStartRecording, onAddJavascriptStep }, ref) => {
  const { menuProps, triggerProps } = useMenu();
  const theme = useTheme();
  const { isFeatureEnabled: enableCustomJsStepFeature } = useFeatureEnabled('custom-code');
  const activeProject = useActiveProject();

  const readyOnly = activeProject?.read_only;

  const handleStartRecording = () => {
    onStartRecording();
    menuProps.onClose();
  };

  const handleAddJavascriptClick = () => {
    onAddJavascriptStep();
    menuProps.onClose();
  };

  return (
    <div>
      <Tooltip title="Click to see more actions">
        <IconButton
          {...triggerProps}
          disabled={!!readyOnly}
          size={'small'}
          color={'primary'}
          aria-label={'Start recording from this step'}>
          <LoupeIcon sx={{ fontSize: '2.1rem' }} color={'success'} />
        </IconButton>
      </Tooltip>
      <Menu id="action-menu" {...menuProps} elevation={1}>
        <Flex sx={{ px: 2 }} justifyContent={'space-between'}>
          <Typography variant="body1">Select an action</Typography>
          <IconButton size={'small'} onClick={() => menuProps.onClose()}>
            <CloseIcon />
          </IconButton>
        </Flex>
        <MenuItem
          disabled={!!readyOnly || !enableCustomJsStepFeature}
          sx={{ color: 'inherit' }}
          onClick={handleAddJavascriptClick}>
          <Flex>
            <CreateNewFolderIcon color={'secondary'} />
            <Typography variant={'body2'}>Add Javascript step</Typography>
            {!enableCustomJsStepFeature && <PremiumBadge />}
          </Flex>
        </MenuItem>
        <MenuItem disabled={!!readyOnly} sx={{ color: 'inherit' }} onClick={handleStartRecording}>
          <Tooltip title={'Click to start recording and add new steps after this one.'}>
            <Flex>
              <EventRecordSvg fill={theme.palette.svg.main} color={'#24C3D9'} />
              <Typography variant={'body2'}>Record from this step</Typography>
            </Flex>
          </Tooltip>
        </MenuItem>
      </Menu>
    </div>
  );
});

const EventCard = ({
  event,
  isActive,
  disabled,
  onExpandSettings,
  onStartPlayback,
  isSelected,
  isActionsExpanded,
  onExpandActions,
  onSelect,
  onStartRecording,
  onInsertBreakpoint,
  onViewDetails,
  isFirstStep,
  onAddJavascriptStep,
  addCustomJavascriptStep
}) => {
  const showMultiSelect = !!onSelect;
  const activeProject = useActiveProject();
  const readyOnly = activeProject?.read_only;
  const theme = useTheme();

  return (
    <GridItem
      backgroundColor={rgba('#24C3D9', event.preview ? 15 : 33)}
      sx={{
        borderRadius: '8px',
        position: 'relative',
        border: isActive ? '2px solid #1958B8' : undefined
      }}>
      {event.preview ? (
        <Box height={'10rem'} width={'100%'}></Box>
      ) : (
        <>
          {event.status && <Badge status={event.status} />}
          <Box
            sx={{
              width: '100%',
              borderRadius: '8px',
              height: '7rem',
              position: 'relative',
              overflow: 'hidden'
            }}>
            {showMultiSelect && !isFirstStep && !!onExpandSettings && !readyOnly && (
              <Box
                position={'absolute'}
                right={0}
                top={0}
                backgroundColor={theme.palette.background.default}
                sx={{ borderBottomLeftRadius: '8px' }}>
                <Checkbox
                  size={'small'}
                  sx={{
                    '&.Mui-checked': {
                      color: theme.palette.radio.main
                    }
                  }}
                  onChange={() => onSelect(!isSelected)}
                  checked={isSelected}
                />
              </Box>
            )}
            <Box
              as={'img'}
              src={getUrl(event.screenshot)}
              sx={{ objectFit: 'cover', width: '100%', height: '90%' }}
            />
          </Box>
          <Box position={'relative'} sx={{ px: 1.5, py: 1.5 }} height={'2rem'}>
            {event.conditions?.length ? <PreconditionIndicator /> : null}
            <ActionIcons
              isExpanded={isActionsExpanded}
              onExpand={onExpandActions}
              onExpandSettings={onExpandSettings}
              onViewDetails={onViewDetails}
              onStartPlayback={onStartPlayback}
              disabled={disabled}
              onInsertBreakpoint={onInsertBreakpoint}
              onStartRecording={onStartRecording}
              onAddJavascriptStep={onAddJavascriptStep}
            />
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontWeight: 500,
                mt: -1.5,
                color: theme.palette.text.black_grey,
                fontSize: '0.9rem',
                whiteSpace: 'nowrap'
              }}>
              {event.title}
            </Typography>
            {/* <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: '0.6rem',
                color: '#787878'
              }}>
              {event.page?.url}
            </Typography> */}
          </Box>
        </>
      )}
    </GridItem>
  );
};

const GroupStepsCard = ({
  steps,
  onExpandSettings,
  expandedActionsStepId,
  onExpandActions,
  onViewStepDetails,
  onStartPlayback,
  onUpdateSteps,
  activeSettingStepId
}) => {
  const py = 2;
  const padding = py * 8;
  // Indicator size is ties to py because py dictates the space available above the card to show the indicator
  const indicatorSize = Math.sqrt((padding * 2) ** 2 / 2);
  // This is a hack to calculate the card height, but we need to figure out a better way to do this in the future
  const cardRef = React.useRef(null);
  const [cardHeight, setCardHeight] = React.useState(0);

  // Keep a debounced local copy of group steps in case a group has many steps and the test has many groups and this operation turns out to be expensive
  const [localGroupSteps, setLocalGroupSteps] = React.useState(steps);
  const debouncedLocalGroupSteps = useDebounce(localGroupSteps, 500);

  // update the local steps when the steps prop changes
  useEffect(() => {
    setLocalGroupSteps(steps);
  }, [steps]);

  useEffect(() => {
    if (!_.isEqual(debouncedLocalGroupSteps, steps)) onUpdateSteps(debouncedLocalGroupSteps);
  }, [debouncedLocalGroupSteps]);

  const findCardIndex = useCallback(
    (id) => {
      return localGroupSteps.findIndex((event) => event.id === id);
    },
    [localGroupSteps]
  );

  const reorderSteps = useCallback(
    (dragIndex, hoverIndex) => {
      if (hoverIndex === dragIndex) return;

      const newSteps = localGroupSteps.map(({ preview, ...event }) => event);
      const draggedCard = newSteps[dragIndex];

      newSteps.splice(dragIndex, 1); // Remove the dragged card
      newSteps.splice(hoverIndex, 0, { ...draggedCard, preview: true }); // Add the empty card at the hovered position
      setLocalGroupSteps(newSteps);
    },
    [localGroupSteps, setLocalGroupSteps]
  );

  const handleDragEnd = useCallback(() => {
    const newSteps = localGroupSteps.map(({ preview, ...event }) => event);
    setLocalGroupSteps(newSteps);
  }, [localGroupSteps, setLocalGroupSteps]);

  useEffect(() => {
    if (cardRef.current && cardRef.current.offsetHeight !== cardHeight)
      setCardHeight(cardRef.current.offsetHeight);
    //   Should probably listen to window resize events here as well because it could have some really ugly repercussions
  });

  return (
    <Box height={cardHeight} display={'flex'} justifyContent={'center'}>
      <Box
        sx={{
          transform: 'rotate(45deg)',
          position: 'absolute',
          width: `${indicatorSize}px`,
          height: `${indicatorSize}px`,
          borderTop: '1px solid #EBEBEB',
          borderLeft: '1px solid #EBEBEB',
          marginTop: '5px',
          zIndex: Z_INDEXES.GROUP_STEPS + 1,
          bgcolor: '#F9F9F9'
        }}></Box>
      <Box
        px={1}
        py={py}
        position={'absolute'}
        sx={{
          left: 0,
          right: 0,
          zIndex: Z_INDEXES.GROUP_STEPS
        }}>
        <Box
          sx={{
            width: '100%',
            minHeight: '300px',
            bgcolor: '#F9F9F9',
            border: '1px solid #EBEBEB',
            borderRadius: '8px'
          }}
          ref={cardRef}
          px={2}
          py={2}>
          <Flex
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            position={'relative'}
            flexWrap={'wrap'}
            columnGap={0}
            sx={{ ml: -1, mr: -1, mt: -2, flex: 1 }}>
            {localGroupSteps.map((step, index) => {
              return (
                <DraggableCard
                  id={step.id}
                  index={index}
                  moveCard={reorderSteps}
                  type={'moveable-group'}
                  onDragEnd={handleDragEnd}
                  findCardIndex={findCardIndex}
                  sx={eventCardSx}
                  key={index}>
                  <EventCard
                    isActionsExpanded={expandedActionsStepId === step.id}
                    onExpandActions={(expand) => onExpandActions(expand ? step.id : null)}
                    onExpandSettings={onExpandSettings && (() => onExpandSettings(index))}
                    event={{
                      ...step
                    }}
                    onViewDetails={() => onViewStepDetails(index)}
                    isActive={activeSettingStepId === step.id}
                    onStartPlayback={() => onStartPlayback(index)}
                    // onStartRecording={!!onUpdateEvent && (() => handleRecord(index + 1))}
                  />
                </DraggableCard>
              );
            })}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export const GroupCard = ({
  group,
  disabled,
  onExpandSettings,
  onStartPlayback,
  runResultsById,
  onInsertBreakpoint,
  onUpdateGroup,
  onExpand,
  expandedActionsStepId,
  onExpandActions,
  isExpanded,
  isViewingSettings,
  onViewStepDetails,
  activeSettingStepId,
  onAddJavascriptStep
}) => {
  const runStatus =
    runResultsById[group.id]?.status ||
    (Object.keys(runResultsById).length === 0
      ? null
      : group.steps.every((step) => runResultsById[step.id]?.status === 'skipped')
      ? 'skipped'
      : group.steps.every((step) =>
          ['skipped', 'success'].includes(runResultsById[step.id]?.status)
        )
      ? 'success'
      : group.steps.some((step) => runResultsById[step.id]?.status === 'error')
      ? 'error'
      : null);

  return (
    <>
      <GridItem
        backgroundColor={rgba('#24C3D9', group.preview ? 15 : 33)}
        sx={{
          borderRadius: '8px',
          position: 'relative',
          border: isViewingSettings ? '2px solid #1958B8' : undefined
        }}>
        {group.preview ? (
          <Box height={'8rem'} width={'100%'}></Box>
        ) : (
          <>
            {runStatus && <Badge status={runStatus} />}
            <Box
              sx={{
                width: '100%',
                borderRadius: '8px',
                height: '6.8rem',
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#EBFDFF'
              }}>
              <GroupSvg width={'45px'} />
            </Box>
            <Box position={'relative'} sx={{ px: 1.5, py: 2.5 }} height={'2rem'}>
              {group.meta?.conditions?.length ? <PreconditionIndicator /> : null}
              <ActionIcons
                onExpandSettings={onExpandSettings}
                onViewDetails={onExpand}
                onStartPlayback={onStartPlayback}
                disabled={disabled}
                onInsertBreakpoint={onInsertBreakpoint}
                isExpanded={expandedActionsStepId === group.id}
                onExpand={(expand) => onExpandActions(expand ? group.id : null)}
                onAddJavascriptStep={onAddJavascriptStep}
              />
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  fontWeight: 600,
                  mt: -2,
                  whiteSpace: 'nowrap'
                }}>
                {group.title}
              </Typography>
              <Typography
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  mt: -0.5,
                  fontSize: '0.6rem',
                  color: '#787878'
                }}>
                {group.steps.length} {pluralize(group.steps.length, 'step')}
              </Typography>
            </Box>
          </>
        )}
      </GridItem>
      {isExpanded && (
        <GroupStepsCard
          onViewStepDetails={onViewStepDetails}
          expandedActionsStepId={expandedActionsStepId}
          onExpandActions={onExpandActions}
          onUpdateSteps={(newSteps) => {
            onUpdateGroup({
              id: group.id,
              steps: newSteps
            });
          }}
          onStartPlayback={onStartPlayback}
          steps={group.steps.map((step) => ({
            ...step,
            ...runResultsById[step.id],
            screenshot: step.screenshot || runResultsById[step.id]?.result_screenshot
          }))}
          onExpandSettings={onExpandSettings}
          activeSettingStepId={activeSettingStepId}
        />
      )}
    </>
  );
};

export default EventCard;
