import React, { useContext, useEffect, useState } from 'react';
import { TestCaseContext } from '../store/testState';
import { extractPathVariablesKey } from '../pages/ApiSuites/utils';

export const useDebounceSearchValue = (delay = 1200) => {
  const { searchValue } = useContext(TestCaseContext);
  const [debounceSearchValue, setDebounceSearchValue] = useState(searchValue);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounceSearchValue(searchValue);
    }, delay);
    return () => clearTimeout(timeout);
  }, [searchValue]);
  return { debounceSearchValue };
};
export const useDebouncePathVariable = (delay = 500, url) => {
  const {
    setTestCaseData,
    testCaseData: { pathVariables: prevPathVariables }
  } = useContext(TestCaseContext);
  useEffect(() => {
    const pathVariablesKeys = extractPathVariablesKey(url);
    const timeout = setTimeout(() => {
      if (!!pathVariablesKeys?.length) {
        const newPathVariables = pathVariablesKeys.map((key, index) => {
          return {
            key,
            value: prevPathVariables[index]?.value || '',
            description: prevPathVariables[index]?.description || ''
          };
        });
        setTestCaseData((prev) => {
          return { ...prev, pathVariables: newPathVariables };
        });
      } else {
        setTestCaseData((prev) => {
          return { ...prev, pathVariables: [] };
        });
      }
    }, delay);
    return () => clearTimeout(timeout);
  }, [url]);
  // return { debounceSearchValue };
};
