import './App.css';
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';
import Login from './pages/authentication/login';
import ResetPassword from './pages/authentication/resetPassword';
import Register from './pages/authentication/register';
import VerifyEmail from './pages/authentication/verifyEmail';
import ChangePassword from './pages/settings/changePassword';
import Projects from './pages/projects';
import ResponsiveAppBar from './components/navigation/nav';
import Sidebar from './components/sidebar';
import PersonalInformation from './pages/settings/personalInformation';
import NewProject from './pages/projects/newProject';
import TestPage from './pages/TestPage';
import VerifyOtp from './pages/authentication/verifyOtp';
import ChangePasswordAfterReset from './pages/authentication/changePassword';
import ProjectFolderSidebar from './components/ProjectFolderSidebar';
import ProjectFolders from './pages/projects/ProjectFolders';
import NewProjectFolder from './pages/projects/NewProjectFolder';
import Dashboard from './pages/dashboard/Dashboard';
import { AppRoutes, AIAgentRoutes, ProjectRoutes } from './routes';
import ProjectRedirect from './components/ProjectRedirect';
import { stripLeadingSlash } from './utils/strings';
import TestRuns from './pages/TestRuns/TestRuns';
import VerifyInviteCode from './pages/authentication/VerifyInviteCode';
import Members from './pages/projects/Members';
import GroupedSteps from './pages/GroupedSteps/GroupedSteps';
import SuitesRuns from './pages/SuitesRuns/SuitesRuns';
import NewTestSuite from './pages/TestSuites/NewTestSuite';
import TestSuites from './pages/TestSuites/TestSuites';
import NewApi from './pages/ApiSuites/NewApi';
import ApiSuiteAutomation from './pages/ApiSuites/ApiSuitesAutomation/ApiSuiteAutomationPage';
import CreateOrEditSuite from './pages/ApiSuites/ApiSuitesAutomation/CreateOrEditSuitePage';
import MailBox from './pages/email/MailBox';
import Settings from './pages/settings/Settings';
import Domains from './pages/settings/Domains';
import Mobile from './pages/Mobile/Mobile';
import MobileTestPage from './pages/Mobile/MobileTestPage';
import Error404Page from './components/base/Error404Page';
import AppDetails from './pages/Mobile/components/AppDetails';
import RunsAndReports from './pages/Mobile/RunsAndReports';
import CompanyProjects from './pages/settings/CompanyProjects';
import Notifications from './pages/projects/Notifications';
import ApiSuiteRuns from './pages/ApiSuites/ApiSuitesAutomation/SuiteRunsPage';
import WebTestSuite from './pages/TestSuites/WebTestSuite';
import Reports from './pages/Reports/Reports';
import SuiteSideBar from './components/SuiteSidebar';
import GlobalVariables from './pages/projects/GlobalVariables';
import { ColorContext } from './AppTheme';
import mixpanel from 'mixpanel-browser';
import DefaultSettings from './pages/projects/DefaultSettings';

const GOOGLE_ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
  persistence: 'localStorage',
  record_sessions_percent: 1
});

const BASE_PROJECT_ROUTE = '/projects/:projectId/';

function App() {
  const { setMode } = useContext(ColorContext);
  useEffect(() => {
    const mode = localStorage.getItem('scandium-ui-mode');
    if (!mode) {
      localStorage.setItem('scandium-ui-mode', 'light');
    } else {
      setMode(mode);
    }
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const ProjectsSidebarItems = [
    AppRoutes.projects.children.index,
    AppRoutes.projects.children.newProject,
    AppRoutes.projects.children.members,
    AppRoutes.projects.children.notifications,
    AppRoutes.projects.children.variables,
    AppRoutes.projects.children.defaults
  ].map((route) => ({
    ...route,
    path: `${AppRoutes.projects.path}/${route.path}`
  }));

  const ProjectFolderSidebarItems = Object.values(AppRoutes.folders.children);
  const OrganizationsSideBarItems = Object.values(AppRoutes.organizations.children);

  return (
    <div className="App">
      <Routes>
        {/* <Route path={AIAgentRoutes.homepage.path} element={<AIHomePage/>} /> */}
        <Route path={AppRoutes.login.path} element={<Login />} />
        <Route path={AppRoutes.register.path} element={<Register />} />
        <Route path={`${AppRoutes.register.path}/?account_type=personal`} element={<Register />} />
        <Route
          path={`${AppRoutes.register.path}/?account_type=enterprise`}
          element={<Register />}
        />
        <Route path={AppRoutes.registerInvitee.path} element={<Register />} />
        <Route path={AppRoutes.verifyEmail.path} element={<VerifyEmail />} />
        <Route path={AppRoutes.resetPassword.path} element={<ResetPassword />} />
        <Route path={AppRoutes.verifyOtp.path} element={<VerifyOtp />} />
        <Route path={AppRoutes.changePassword.path} element={<ChangePasswordAfterReset />} />
        <Route path={AppRoutes.joinProject.path} element={<VerifyInviteCode />} />
        <Route path={AppRoutes.mailBox.path} element={<MailBox />} />

        <Route element={<ResponsiveAppBar />}>
          <Route
            path={AppRoutes.projects.path}
            element={<Sidebar SidebarItems={ProjectsSidebarItems} />}>
            <Route index element={<Projects />} />
            <Route path={AppRoutes.projects.children.newProject.path} element={<NewProject />} />
            <Route
              exact
              path={AppRoutes.projects.children.editProject.path}
              element={<NewProject />}
            />
            <Route path={AppRoutes.projects.children.members.path} element={<Members />} />
            <Route
              path={AppRoutes.projects.children.notifications.path}
              exact
              element={<Notifications />}
            />
            <Route
              path={AppRoutes.projects.children.defaults.path}
              exact
              element={<DefaultSettings />}
            />
            <Route
              path={AppRoutes.projects.children.variables.path}
              exact
              element={<GlobalVariables />}
            />
          </Route>

          {/*Routes specific to a project*/}
          <Route path={BASE_PROJECT_ROUTE}>
            <Route index element={<Navigate to={'dashboard'} />} />
            <Route exact path={stripLeadingSlash(AppRoutes.runs.path)} element={<Sidebar />}>
              <Route index element={<TestRuns />} />
            </Route>
            <Route
              exact
              path={stripLeadingSlash(`${AppRoutes.runs.path}/:testId`)}
              element={<Sidebar />}>
              <Route index element={<TestRuns />} />
            </Route>

            <Route exact path={stripLeadingSlash(AppRoutes.groups.path)} element={<Sidebar />}>
              <Route index element={<GroupedSteps />} />
            </Route>

            <Route exact path={stripLeadingSlash(AppRoutes.groups.path)} element={<Sidebar />}>
              <Route index element={<GroupedSteps />} />
            </Route>

            <Route
              path={stripLeadingSlash(AppRoutes.folders.path)}
              element={<ProjectFolderSidebar SidebarItems={ProjectFolderSidebarItems} />}>
              <Route index element={<ProjectFolders />} />
              <Route
                path={AppRoutes.folders.children.newFolder.path}
                element={<NewProjectFolder />}
              />
              <Route path={':folderId/edit'} element={<NewProjectFolder />} />
            </Route>

            <Route
              path={stripLeadingSlash(`${AppRoutes.folders.path}/tests-group/:groupId`)}
              element={<ProjectFolderSidebar SidebarItems={ProjectFolderSidebarItems} />}>
              <Route index element={<ProjectFolders />} />
              <Route
                path={AppRoutes.folders.children.newFolder.path}
                element={<NewProjectFolder />}
              />
            </Route>

            <Route
              path={`${stripLeadingSlash(AppRoutes.folders.path)}/${
                AppRoutes.folders.children.newTest.path
              }`}
              element={<TestPage />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.folders.path)}/tests/:testId/edit`}
              element={<TestPage />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.folders.path)}/tests/:testId/runs/:batchId`}
              element={<TestPage previewMode />}
            />

            {/*Single folder*/}
            <Route
              path={stripLeadingSlash(AppRoutes.folder.path)}
              element={
                <ProjectFolderSidebar SidebarItems={Object.values(AppRoutes.folder.children)} />
              }>
              <Route index element={<ProjectFolders />} />
              <Route
                path={AppRoutes.folder.children.newFolder.path}
                element={<NewProjectFolder />}
              />
            </Route>

            <Route path={`${stripLeadingSlash(AppRoutes.folder.path)}`}>
              <Route element={<TestPage />} path={AppRoutes.folder.children.newTest.path}></Route>
              <Route element={<TestPage />} path={'tests/:testId/edit'}></Route>
            </Route>

            <Route path={stripLeadingSlash(AppRoutes.dashboard.path)} element={<Sidebar />}>
              <Route index element={<Dashboard />} />
            </Route>

            {/* Test Suites */}
            <Route
              path={stripLeadingSlash(AppRoutes.suites.path)}
              element={<Sidebar SidebarItems={Object.values(AppRoutes.suites.children)} />}>
              <Route index element={<TestSuites />} />
              <Route path={AppRoutes.suites.children.newSuite.path} element={<NewTestSuite />} />
              <Route path={`:suiteId/edit`} element={<NewTestSuite />} />
            </Route>
            <Route
              exact
              path={`${stripLeadingSlash(AppRoutes.suites.path)}/:suiteId`}
              element={<SuiteSideBar />}>
              <Route index element={<WebTestSuite />} />
            </Route>

            {/* Suites Runs */}
            <Route exact path={stripLeadingSlash(AppRoutes.suitesRuns.path)} element={<Sidebar />}>
              <Route index element={<SuitesRuns />} />
            </Route>
            <Route
              exact
              path={stripLeadingSlash(`${AppRoutes.suitesRuns.path}/:suiteId`)}
              element={<Sidebar />}>
              <Route index element={<SuitesRuns />} />
            </Route>

            {/* Reports */}
            <Route path={stripLeadingSlash(AppRoutes.reports.path)} element={<Reports />} />

            {/* Mobile Testing */}
            <Route
              path={stripLeadingSlash(AppRoutes.mobileTesting.path)}
              element={<Sidebar SidebarItems={[]} />}>
              <Route index element={<Mobile />} />
              <Route path=":publicKey/app" element={<AppDetails />} />
              <Route path=":publicKey/app/:folderId" element={<AppDetails />} />
            </Route>

            <Route
              path={`${stripLeadingSlash(AppRoutes.mobileTesting.path)}/:publicKey/new-test`}
              element={<MobileTestPage />}
            />

            <Route
              path={`${stripLeadingSlash(
                AppRoutes.mobileTesting.path
              )}/:publicKey/:folderId/new-test`}
              element={<MobileTestPage />}
            />

            <Route
              path={`${stripLeadingSlash(
                AppRoutes.mobileTesting.path
              )}/:publicKey/:testId/:folderId/edit`}
              element={<MobileTestPage />}
            />

            <Route
              path={`${stripLeadingSlash(AppRoutes.mobileTesting.path)}/:publicKey/:testId/edit`}
              element={<MobileTestPage />}
            />

            <Route
              path={`${stripLeadingSlash(
                AppRoutes.mobileTesting.path
              )}/:publicKey/:testId/:folderId/edit`}
              element={<MobileTestPage />}
            />

            <Route
              path={`${stripLeadingSlash(
                AppRoutes.mobileTesting.path
              )}/:publicKey/:testId/runs/:batchId`}
              element={<MobileTestPage previewMode />}
            />

            <Route
              path={`${stripLeadingSlash(
                AppRoutes.mobileTesting.path
              )}/:publicKey/:folderId/:testId/runs/:batchId`}
              element={<MobileTestPage previewMode />}
            />
            {/* Mobile test runs */}
            <Route
              path={stripLeadingSlash(AppRoutes.mobileTestRuns.path)}
              element={<Sidebar SidebarItems={[]} />}>
              <Route index element={<RunsAndReports />} />
            </Route>

            <Route
              exact
              path={stripLeadingSlash(`${AppRoutes.mobileTestRuns.path}/:testId`)}
              element={<Sidebar />}>
              <Route index element={<RunsAndReports />} />
            </Route>

            {/* API Suites */}
            {/* <Route
              path={stripLeadingSlash(AppRoutes.apiSuites.path)}
              element={<Sidebar SidebarItems={Object.values(AppRoutes.apiSuites.children)} />}>
              <Route index element={<ApiTestCases />} />
            </Route> */}

            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/new`}
              element={<NewApi />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/:folderId/new`}
              element={<NewApi />}
            />

            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/:folderId/:testId/edit`}
              element={<NewApi />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/:folderId/:testId/edit/search?`}
              element={<NewApi />}
            />

            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/:testId/edit`}
              element={<NewApi />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/:testId/edit/search?`}
              element={<NewApi />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/:testId/example/:exampleId`}
              element={<NewApi />}
            />
            <Route
              path={`${stripLeadingSlash(
                AppRoutes.apiSuites.path
              )}/:folderId/:testId/example/:exampleId`}
              element={<NewApi />}
            />
            {/* <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/automate`}
              element={<ApiSuiteAutomation />}
            /> */}

            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/automate/new`}
              element={<CreateOrEditSuite />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/api_suite_runs`}
              element={<ApiSuiteRuns />}
            />
            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/automate/edit/:api_suite_id`}
              element={<CreateOrEditSuite />}
            />
            {/* <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/automate/new`}
              element={<Sidebar SidebarItems={[]} />}>
              <Route index element={<CreateOrEditSuite />} />
            </Route> */}
            <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/automate`}
              element={<Sidebar SidebarItems={[]} />}>
              <Route index element={<ApiSuiteAutomation />} />
            </Route>

            {/* <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/automate/edit/:api_suite_id`}
              element={<Sidebar SidebarItems={[]} />}>
              <Route index element={<CreateOrEditSuite />} />
            </Route> */}

            {/* <Route
              path={`${stripLeadingSlash(AppRoutes.apiSuites.path)}/api_suite_runs`}
              element={<Sidebar SidebarItems={[]} />}>
              <Route index element={<ApiSuiteRuns />} />
            </Route> */}
          </Route>
          {/*Settings*/}
          <Route
            path={AppRoutes.settings.path}
            element={<Sidebar SidebarItems={Object.values(AppRoutes.settings.children)} />}>
            {/* <Route index element={<Settings />} /> */}
            <Route
              path={AppRoutes.settings.children.personalInformation.path}
              element={<PersonalInformation />}
            />
            <Route
              path={AppRoutes.settings.children.changePassword.path}
              element={<ChangePassword />}
            />
          </Route>
          <Route
            path={AppRoutes.organizations.path}
            element={<Sidebar SidebarItems={OrganizationsSideBarItems} />}>
            <Route index element={<Settings />} />
            <Route path={AppRoutes.organizations.children.domains.path} element={<Domains />} />
            <Route
              path={AppRoutes.organizations.children.projects.path}
              element={<CompanyProjects />}
            />
          </Route>

          {/*Redirect routes*/}
          {Object.values(ProjectRoutes).map((route) => (
            <Route path={route.path} element={<ProjectRedirect />} key={route.path}>
              {Object.values(route.children || {}).map((childRoute, index) => (
                <Route key={index} path={childRoute.path} element={<ProjectRedirect />} />
              ))}
            </Route>
          ))}
        </Route>

        {/*404 page*/}
        <Route path={'*'} element={<Error404Page />} />
      </Routes>
    </div>
  );
}

export default App;
