import { Box, Menu, MenuItem, Typography } from '@mui/material';
import Flex from '../../../components/base/Flex';
import { useMenu } from '../../../components/base/useMenu';
import CustomButton from '../../../components/customButton';
import { useTheme } from '@emotion/react';
import { useContext, useEffect } from 'react';
import { ColorContext } from '../../../AppTheme';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useLocation, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import CustomScrollbar from '../../TestPage/components/CustomScrollbar';

const label = { inputProps: { 'aria-label': 'Tags checkbox' } };

const TagsFIlter = ({ selectedTags, setSelectedTags, tags }) => {
  const { triggerProps, menuProps } = useMenu();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const theme = useTheme();
  const { mode } = useContext(ColorContext);

  const handleTagsChange = (tag) => {
    let newSelectedTags = [...selectedTags];
    const existingIndex = newSelectedTags.findIndex((existingTag) => existingTag.id === tag.id);

    if (existingIndex !== -1) {
      newSelectedTags.splice(existingIndex, 1);
    } else {
      newSelectedTags.push(tag);
    }
    setSelectedTags(newSelectedTags);

    const tagIds = newSelectedTags.map((updatedTag) => updatedTag.id);
    updateUrlQueryParams('tags', tagIds.join('-'));
  };

  const updateUrlQueryParams = (paramName, value) => {
    if (value) {
      queryParams.set(paramName, value);
    } else {
      queryParams.delete(paramName);
    }
    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    const filterByQueryParams = (paramKey, dataList, setData) => {
      const paramString = queryParams.get(paramKey);
      const paramIds = paramString?.split('-')?.map(Number);

      if (paramString && !!paramIds.length) {
        const filteredData = dataList?.filter((obj) => paramIds.includes(obj.id));
        setData(filteredData);
      }
    };

    filterByQueryParams('tags', tags, setSelectedTags);
  }, [tags]);

  const isTagSelected = (tag) => {
    return selectedTags.some((selectedTag) => selectedTag.id === tag.id);
  };

  return (
    <Box>
      <CustomButton
        disableRipple
        disableFocusRipple
        disableElevation
        {...triggerProps}
        fullWidth
        variant={'outlined'}
        label={
          <Flex>
            <FilterListIcon color={theme.palette.svg.primary} />{' '}
            <Typography variant={'body1'}>Filter by Tags</Typography>
          </Flex>
        }
        sx={{
          textAlign: 'center',
          textTransform: 'capitalize',
          borderRadius: '0.4rem',
          color: theme.palette.button.textOutlined,
          border: mode === 'dark' && `1px solid ${theme.palette.button.border}`,
          py: { xs: '0.2rem', sm: '0.3rem' },
          px: 2,
          mr: 1,
          width: 'max-content',
          '&: hover': {
            background: 'transparent'
          }
        }}
      />
      <Menu
        id="action-menu"
        {...menuProps}
        elevation={1}
        PaperProps={{
          style: {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}>
        <CustomScrollbar sx={{ overflowY: 'auto', maxHeight: '250px' }}>
          {tags?.length ? (
            tags?.map((tag) => (
              <MenuItem key={tag.id} onClick={() => handleTagsChange(tag)} sx={{ py: 0 }}>
                <Flex>
                  <Checkbox
                    size={'small'}
                    {...label}
                    checked={!!isTagSelected(tag)}
                    sx={{
                      '&.Mui-checked': {
                        color: theme.palette.radio.main
                      }
                    }}
                  />
                  <Typography variant={'body2'}>{tag.name.en}</Typography>
                </Flex>
              </MenuItem>
            ))
          ) : (
            <Typography
              variant={'body2'}
              textAlign={'center'}
              sx={{ py: 2, fontStyle: 'italic' }}>
              Create and attach tags to your test cases to enable filtering by those tags
            </Typography>
          )}
        </CustomScrollbar>
      </Menu>
    </Box>
  );
};

export default TagsFIlter;
