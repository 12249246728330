import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { StatusTag } from '../../dashboard/components';
import Flex from '../../../components/base/Flex';
import ProjectSvg from '../../../components/svg/ProjectSvg';
import { formatDate } from '../../../utils/formateDate';
import React from 'react';
import { getDuration } from '../../../utils/time';
import ExecutionEnv from '../../TestRuns/components/ExecutionEnv';
import {
  StyledTableRow,
  StyledTableCell2 as StyledTableCell
} from '../../../components/base/Table';
import { Link, useParams } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import { useTheme } from '@emotion/react';

const ResultsTable = ({ suiteRuns, onNavigate }) => {
  const theme = useTheme();
  const { suiteId } = useParams();
  const activeProject = useActiveProject();

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 1, mb: 4 }}>
      <Table sx={{ width: '100%', minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Execution Environment</StyledTableCell>
            <StyledTableCell>Started</StyledTableCell>
            {!suiteId && <StyledTableCell>Execution Type</StyledTableCell>}
            <StyledTableCell>{!!suiteId ? 'Finished' : 'Duration'}</StyledTableCell>
            <StyledTableCell>{!!suiteId ? 'Duration' : 'Results'}</StyledTableCell>
            <StyledTableCell align={'center'}>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suiteRuns?.map((row, index) => (
            <StyledTableRow
              tabIndex={0}
              component={Link}
              sx={{ textDecoration: 'none' }}
              to={
                !suiteId
                  ? `/projects/${activeProject?.id}/suites-results/${row.id}`
                  : `/projects/${activeProject?.id}/folders/tests/${row.test_id}/runs/${row?.id}?limit=50`
              }
              key={index}>
              <StyledTableCell component="th" scope="row">
                <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
                  <ProjectSvg
                    width={24}
                    fill={theme.palette.svg.primary}
                    height={24}
                    color={'primary'}
                  />
                  <Flex columnGap={1} flexDirection={'column'}>
                    <Flex columnGap={1}>
                      <Typography
                        fontWeight={500}
                        color={theme.palette.text.black_white}
                        sx={{ lineHeight: 1, mt: '4px' }}>
                        {row.name || row.suite?.name || ''}
                      </Typography>
                      {!suiteId && (
                        <Typography
                          fontWeight={400}
                          color={theme.palette.text.black_white}
                          sx={{ lineHeight: 1, mt: '4px' }}>
                          {`(${row.source} run)`}
                        </Typography>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </StyledTableCell>
              <StyledTableCell>
                <Flex className={'cell-content-wrapper'} columnGap={0.5}>
                  <ExecutionEnv
                    browser={row.browser.toLowerCase()}
                    os={row.operating_system.toLowerCase()}
                  />
                </Flex>
              </StyledTableCell>
              <StyledTableCell>
                <Box className={'cell-content-wrapper'}>{formatDate(row.started_at)}</Box>
              </StyledTableCell>
              {!suiteId && (
                <StyledTableCell>
                  <Box className={'cell-content-wrapper'}>{row.suite?.execution_type || ''}</Box>
                </StyledTableCell>
              )}
              <StyledTableCell>
                <Box className={'cell-content-wrapper'}>
                  {!!suiteId ? formatDate(row.finished_at) : getDuration(row.duration || 0)}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box className={'cell-content-wrapper'}>
                  {!!suiteId
                    ? getDuration(row.duration || 0)
                    : `${row.summary.passed} of ${row.summary.total} passed`}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Flex className={'cell-content-wrapper'} justifyContent={'center'}>
                  <StatusTag status={row.status} />
                </Flex>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsTable;
