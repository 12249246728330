import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  TableRow,
  TableHead,
  Tooltip
} from '@mui/material';
import { StatusTag, StyledTableCell, StyledTableRow } from '../../dashboard/components';
import Flex from '../../../components/base/Flex';
import { formatDate } from '../../../utils/formateDate';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveProject } from '../../../store/projectState';
import ProjectSvg from '../../../components/svg/ProjectSvg';
import MobileExecutionEnv from './MobileExecutionEnv';
import { getDuration } from '../../../utils/time';
import { useTheme } from '@emotion/react';
import CreateFailureTag from './CreateFailureTagModal';
import useAwaitModal from '../../../hooks/useAwaitModal';
import RunsActionMenu from './RunsActionMenu';
import Reports from '../../TestRuns/components/Reports';

const ResultRow = ({ row, testData, requestFailureTagModal }) => {
  const { testId } = useParams();
  const navigate = useNavigate();
  const activeProject = useActiveProject();
  const theme = useTheme();

  const errorReason = (row.last_run_result?.test_runs || row?.items)?.find((err) => err.error);

  return (
    <StyledTableRow
      sx={{ textDecoration: 'none' }}
      onClick={() =>
        navigate(
          !testId
            ? `/projects/${activeProject?.id}/mobile-test-runs/${row.id}?page=1`
            : `/projects/${activeProject?.id}/mobile-testing/${
                row?.publicKey || testData?.last_run_result?.publicKey
              }/${testData?.folder_id ? `${testData?.folder_id}/` : ''}${testId}/runs/${
                row?.batchId
              }?platform=${row?.platform || testData?.last_run_result?.platform}`
        )
      }>
      {!testData && (
        <StyledTableCell component={'th'} scope={'row'}>
          <Flex className={'cell-content-wrapper'} alignItems={'center'} columnGap={2}>
            <ProjectSvg width={24} fill={theme.palette.svg.primary} height={24} color={'primary'} />
            <Typography
              fontWeight={500}
              sx={{
                lineHeight: 1,
                mt: '4px',
                maxWidth: '300px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}>
              {row.name}
            </Typography>
          </Flex>
        </StyledTableCell>
      )}
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          {formatDate(
            row.started_at ||
              row.last_run_result?.started_at ||
              testData?.last_run_result?.started_at
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          <StatusTag status={row.status || row.last_run_result?.status} />
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          <Tooltip title={errorReason?.error || '---'}>
            <Typography
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: 12
              }}>
              {errorReason?.error || '---'}
            </Typography>
          </Tooltip>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          <Typography variant={'body2'} sx={{ textTransform: 'capitalize' }}>
            {row.last_run_result?.failure_type === 'new ui change'
              ? 'New UI Change'
              : row.last_run_result?.failure_type || row?.failure_type}
          </Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          <MobileExecutionEnv
            platform={
              row.last_run_result?.platform?.toLowerCase() ||
              row?.platform ||
              testData?.last_run_result?.platform?.toLowerCase()
            }
            device={
              row.last_run_result?.device_name?.toLowerCase() ||
              row?.device_name ||
              testData?.device_name?.toLowerCase()
            }
          />
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          {getDuration(
            row.duration ||
              row.last_run_result?.duration ||
              testData?.last_run_result?.duration ||
              0
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>1.0.12 Alpha</Box>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Box className={'cell-content-wrapper'}>
          {row.status === 'error' ? (
            <RunsActionMenu
              onClickCreateTag={() =>
                requestFailureTagModal({
                  testData: row
                })
              }
            />
          ) : null}
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  );
};

const RunsTable = ({
  testRuns,
  testData,
  refetchRuns,
  setMode,
  mode,
  setReportFormat,
  report_format,
  isFetchingReport,
  downloadReport
}) => {
  const { testId } = useParams();
  
  const [
    requestFailureTagModal,
    {
      open: openFailureTagModal,
      onClose: onCloseFailureTagModal,
      onComplete: completeFailureTagModal,
      ...otherProps
    }
  ] = useAwaitModal();

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: 1, mb: 4 }}>
        <Table sx={{ width: '100%', minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {!testData && <StyledTableCell>Test case name</StyledTableCell>}
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Result</StyledTableCell>
              <StyledTableCell>Failure reason</StyledTableCell>
              <StyledTableCell>Failure Tags</StyledTableCell>
              <StyledTableCell>Device/OS</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
              <StyledTableCell align={'left'}>Build No.</StyledTableCell>
              <StyledTableCell align={'left'}>
                {!testId && (
                  <Reports
                    handleDownloadReport={() => downloadReport()}
                    isLoading={isFetchingReport}
                    format={report_format}
                    setReportFormat={setReportFormat}
                    mode={mode}
                    setMode={setMode}
                    runs={testRuns}
                  />
                )}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(testRuns || []).map((row, index) => (
              <ResultRow
                key={index}
                row={row}
                testData={testData}
                requestFailureTagModal={requestFailureTagModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateFailureTag
        open={openFailureTagModal}
        onClose={onCloseFailureTagModal}
        onComplete={completeFailureTagModal}
        refetchRuns={refetchRuns}
        {...otherProps}
      />
    </>
  );
};

export default RunsTable;
