import React, { useContext, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useScandiumMutation, useScandiumQuery } from '../data-layer/utils';
import { TestCaseContext } from './testState';

export const ProjectContext = React.createContext({
  projects: [],
  fetchProjectsError: null,
  isProjectsLoading: false,
  setActiveProject: () => {},
  fetchProjects: () => {}
});
export const useActiveProject = () => {
  const params = useParams();
  const { projects } = useContext(ProjectContext);

  return (
    (params.projectId && projects.find((project) => project.id === params.projectId)) || projects[0]
  );
};

export const ProjectContextProvider = ({ children }) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [consoleData, setConsoleData] = useState([]);
  const [localRun, setLocalRun] = useState(false);
  const [localMode, setLocalMode] = useState(false);
  const [showLocalModeNotice, setShowLocalModeNotice] = useState(false);
  const [isDraggingId, setIsDraggingId] = useState(null);
  const [clickedExample, setClickedExample] = useState('');
  const [clickedFolder, setClickedFolder] = useState('');
  const [clickedTest, setClickedTest] = useState('');
  const [focusedTestId, setFocusedTestId] = useState('');
  const [showDestinationFolder, setShowDestinationFolder] = useState(false);
  const [foldersState, setFoldersState] = useState({
    entityType: '',
    entityToMove: {},
    foldersList: [],
    destinationFolder: {}
  });
  const [, setCookie] = useCookies(['token', 'email']);
  const [massSelection, setMassSelection] = useState({
    active: false,
    selectedApiTests: [],
    selectedApiTestExamples: [],
    selectedFolders: [],
    selectedSuites: []
  });
  const [runAgent, setRunAgent] = useState('Cloud-Agent');
  const [agentPort, setAgentPort] = useState(12621);
  const [filterByTags, setFilterByTags] = useState(null);
  const [isTestChanges, setIsTestChanges] = useState(false);
  const [activeTool, setActiveTool] = useState(null);
  const [testDetails, setTestDetails] = useState({});
  const [testDocumentData, setTestDocumentData] = useState({
    url: '',
    method: '',
    reqParams: [],
    reqHeaders: [],
    reqBody: '',
    mode: '',
    authType: ''
  });
  const [testCaseData, setTestCaseData] = useState({
    pathVariables: []
  });
  const initialTestProps = useRef(null);
  const resetInitialTestProps = () => {
    initialTestProps.current = null;
  };
  const checkIfAgentIsOn = async (cbOnSuccess, cbOnError = () => {}) => {
    let result = false;
    try {
      const response = await fetch(`http://localhost:${agentPort}/api/run-test`);
      if (response.ok) {
        result = true;
        cbOnSuccess();
      }
      if (!response.ok) {
        cbOnError();
      }
    } catch (err) {
      cbOnError();

      // console.log(err);
      // toast.info('Scandium Desktop Agent is not running on your device');
    }
    return result;
  };
  const {
    data: projects = [],
    error: fetchProjectsError,
    isLoading: isProjectsLoading,
    refetch: fetchProjects
  } = useScandiumQuery('/projects', {
    // Only trigger fetch when we have refreshed the token
    params: {
      tag: filterByTags?.name?.en || undefined
    },
    onSuccess: (data) => {
      setCookie('projectType', data[0].type, {
        path: '/',
        sameSite: 'lax',
        secure: true
      });
    },
    select: (data) => {
      let projects = data.projects.sort((a, b) => {
        return b.last_accessed_at < a.last_accessed_at || b.last_accessed_at == null ? -1 : 1;
      });
      return projects;
    }
  });
  const { mutateAsync } = useScandiumMutation('/switch-project', {
    onSuccess: async (data) => {
      if (data.project.id !== projects[0]) await fetchProjects();
    }
  });
  const setActiveProject = async (projectId) => {
    return await mutateAsync({
      project_id: projectId
    });
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        setActiveProject,
        isProjectsLoading,
        fetchProjects,
        fetchProjectsError,
        filterByTags,
        setFilterByTags
      }}>
      <TestCaseContext.Provider
        value={{
          clickedTest,
          setClickedTest,
          clickedFolder,
          setClickedFolder,
          focusedTestId,
          setFocusedTestId,
          showDescriptionModal,
          setShowDescriptionModal,
          massSelection,
          setMassSelection,
          searchValue,
          setSearchValue,
          localRun,
          setLocalRun,
          localMode,
          setLocalMode,
          showLocalModeNotice,
          setShowLocalModeNotice,
          runAgent,
          setRunAgent,
          agentPort,
          setAgentPort,
          checkIfAgentIsOn,
          initialTestProps,
          resetInitialTestProps,
          isTestChanges,
          setIsTestChanges,
          clickedExample,
          setClickedExample,
          isDraggingId,
          setIsDraggingId,
          consoleData,
          setConsoleData,
          testDetails,
          setTestDetails,
          activeTool,
          setActiveTool,
          testDocumentData,
          setTestDocumentData,
          foldersState,
          setFoldersState,
          showDestinationFolder,
          setShowDestinationFolder,
          testCaseData,
          setTestCaseData
        }}>
        {children}
      </TestCaseContext.Provider>
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
